import { useState, useEffect } from 'react';
import { CustomAxios } from '../../custom-axios';
import { Store } from '../../context/store';
import { useStore } from '../../context/use-store.hook';

export const fetchData = async (url, queryParams, setLoading, setData, setError) => {
    setLoading(true);
    try {
        if (queryParams && !!Object.keys(queryParams).length) {
            const queryString = Object.keys(queryParams)
                .map((key) => key + '=' + queryParams[key])
                .join('&');
            url += `?${queryString}`;
        }
        const res = await CustomAxios.get(url);

        if (res) {
            const data = res?.data?.data || res?.data;
            setData(data);
        }
    } catch (error) {
        setError(error);
    }
    setLoading(false);
};

export async function GETPromise<T>(url: string, queryParams: { [key: string]: string }): Promise<T> {
    if (queryParams && !!Object.keys(queryParams).length) {
        const queryString = Object.keys(queryParams)
            .map((key) => key + '=' + queryParams[key])
            .join('&');
        url += `?${queryString}`;
    }
    return CustomAxios.get(url);
}

interface Props {
    url: string;
    queryParams?: Object;
    deps?: any[];
}

export const useResource = ({ url, queryParams = {}, deps = [] }: Props) => {
    const {
        store: { activeLocation },
    } = useStore(Store);
    const [data, setData] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!!activeLocation?.locationId) {
            fetchData(url, queryParams, setLoading, setData, setError);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url, JSON.stringify(queryParams), ...deps]);

    const refresh = () => {
        fetchData(url, queryParams, setLoading, setData, setError);
    };

    return { data, loading, error, refresh };
};
