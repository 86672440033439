import { useContext, useCallback, useEffect } from 'react';
import { Captcha, CaptchaActions, CaptchaStore } from '../../recapcha.provider';

export const useCaptcha = (action: CaptchaActions) => {
    const { storeState, storeDispatch } = useContext(CaptchaStore);
    const { instance } = storeState;
    const captcha = storeState.captcha?.[action];

    const loadToken = useCallback(() => {
        if (!instance) {
            console.warn('there was no captcha instance to execute on');
            return;
        }
        storeDispatch({ type: 'SET-ACTION', payload: { loading: true, token: '', action } });

        instance
            .execute(action)
            .then((token) => {
                storeDispatch({ type: 'SET-ACTION', payload: { loading: false, token, action } });
            })
            .catch(() => {
                console.log('woops');
                const error = new Error(`ReCaptcha v3 failed to execute ${action}`);
                storeDispatch({ type: 'SET-ACTION', payload: { loading: false, token: '', action, error } });
            });
        // eslint-disable-next-line
    }, [instance]);

    const setCaptcha = (captchaData: Captcha<typeof action>) => {
        storeDispatch({ type: 'SET-ACTION', payload: captchaData });
    };

    const clearError = () => {
        if (captcha?.error) {
            const { error, ...rest } = captcha;
            storeDispatch({ type: 'SET-ACTION', payload: rest });
        }
    };

    useEffect(() => {
        if (loadToken) {
            loadToken();
        }
    }, [loadToken]);

    return { captcha, setCaptcha, clearError, loadToken };
};
