import React, { useEffect } from 'react';
import {
    AlertIcon,
    EmailField,
    PhoneField,
    Text,
    TextareaField,
    TextField,
    useForm,
    FormRow,
    DateField,
    DropdownField,
} from '@weave/design-system';
import { Store } from '../../../context/store';
import { useCaptcha } from '../../../hooks/resources/use-captcha.hook';
import { CaptchaActions } from '../../../recapcha.provider';
import { useStore } from '../../../context/use-store.hook';

export const PersonInfoForm = () => {
    const {
        dispatch,
        store: {
            config,
            personInfo: { firstName, lastName, phone, email, birthdate, gender, note },
        },
    } = useStore(Store);
    const { captcha, ...captchaMethods } = useCaptcha(CaptchaActions.bookAppointment);
    const { getFieldProps, formProps, values, isComplete } = useForm({
        fields: {
            firstName: { type: 'text', value: firstName, required: true },
            lastName: { type: 'text', value: lastName, required: true },
            phone: { type: 'phone', value: phone, required: true },
            email: { type: 'email', value: email, required: true },
            birthdate: { type: 'date', value: birthdate, required: false },
            gender: { type: 'dropdown', value: gender },
            note: { type: 'text', value: note },
        },
        computeChangedValues: true,
    });

    useEffect(() => {
        dispatch({ type: 'PERSON-INFO', payload: { ...values, isComplete } });
        // eslint-disable-next-line
    }, [Object.values(values).toString()]);

    useEffect(() => {
        if (captcha?.error) {
            setTimeout(() => {
                captchaMethods.clearError();
            }, 2000);
        }
        // eslint-disable-next-line
    }, [captcha?.error, captchaMethods]);

    return (
        <form {...formProps}>
            <Text weight="bold">Patient Info</Text>
            <FormRow>
                <TextField {...getFieldProps('firstName')} name="firstName" label="First Name" />
            </FormRow>
            <FormRow>
                <TextField {...getFieldProps('lastName')} name="lastName" label="Last Name" />
            </FormRow>
            <FormRow>
                <PhoneField {...getFieldProps('phone')} name="phone" label="Phone Number" />
            </FormRow>
            <FormRow>
                <EmailField {...getFieldProps('email')} name="email" label="Email" />
            </FormRow>
            <FormRow>
                <DateField {...getFieldProps('birthdate')} name="birthdate" label="Date of Birth" />
            </FormRow>
            <FormRow>
                <DropdownField {...getFieldProps('gender')} name="gender" label="Gender">
                    <DropdownField.Option value="Male">Male</DropdownField.Option>
                    <DropdownField.Option value="Female">Female</DropdownField.Option>
                    <DropdownField.Option value="">Other</DropdownField.Option>
                </DropdownField>
            </FormRow>
            <FormRow>
                <TextareaField {...getFieldProps('note')} name="note" label="Note" />
            </FormRow>
            {captcha?.error && (
                <>
                    <AlertIcon color="error" />
                    <Text color="error" size="medium">
                        {captcha?.error?.message}
                    </Text>
                </>
            )}
            <Text color="light" size="small">
                Heads up, this form is using google's reCaptcha to verify the requests. By providing your phone number,
                you consent to receiving SMS text messages and phone calls from {config.locationName || 'us'}, including
                texts and calls that promote our products and services. Text message and data rates may apply. You may
                withdraw your consent at any time. Reply STOP to any text message to unsubscribe, or HELP for more
                options.
            </Text>
        </form>
    );
};
