import { theme } from '@weave/theme-original';
import { css } from '@emotion/core';
import React, { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';
import { Store } from '../../../context/store';
import { useStore } from '../../../context/use-store.hook';
import { NakedButton, UserIcon, useFormField, RadioField, FamilyIcon } from '@weave/design-system';
import { CustomAxios } from '../../../custom-axios';
import { defaultSelectedProviderUnavailability } from '../../../context/store-defaults';

const itemContainer = css`
    display: flex;
    flex-direction: column;
`;
const providerItem = ({ id, value }: { id: string; value: string }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: ${theme.spacing(1)};
    padding: ${theme.spacing(2)} !important;
    text-align: left;
    border: 1px solid ${id === value ? theme.colors.weaveBlue : theme.colors.gray400};
    border-radius: ${theme.borderRadius.medium};
`;
const avatarContainer = css`
    margin-right: ${theme.spacing(2)};
    padding: ${theme.spacing(1)};
    border-radius: 50px;
    background: ${theme.colors.gray400};
`;
const imgStyles = css`
    height: 48px;
    width: 48px;
    margin-right: ${theme.spacing(2)};
    border-radius: 50%;
    border: 1px solid ${theme.colors.gray400};
`;

const defaultProviderSelection = { id: 'all', providerName: 'Any Provider', available: true };

export const getAllProviders = async (setAllProviders, dispatch): Promise<void> => {
    try {
        const providersResponse = await CustomAxios.get('schedule/api/v2/providers');
        const providersData =
            providersResponse.data.providers?.map((provider) => ({
                id: provider.id,
                name: `${provider.firstName} ${provider.lastName}`,
            })) || [];
        dispatch({
            type: 'SET-ALL-PROVIDERS',
            payload: providersData,
        });
        setAllProviders(providersData);
    } catch (err) {
        console.error(err);
    }
};

const getProviderUnavailability = (providerId: string) =>
    new Promise(async (resolve, reject) => {
        CustomAxios.get(`/schedule/api/v2/providers/${providerId}/closed-times`)
            .then(async (res) => {
                const data = {};
                await Object.keys(res.data).forEach(async (dayKey) => {
                    data[dayKey] = await res.data[dayKey].map((timeblock) => {
                        const { durationMinutes, timeOfDay } = timeblock.time;
                        const { hours, minutes } = timeOfDay;
                        return {
                            hours: hours || 0,
                            minutes: minutes || 0,
                            durationMinutes: durationMinutes || 0,
                        };
                    });
                });
                resolve(data);
            })
            .catch((err) => {
                console.error(err);
                resolve(defaultSelectedProviderUnavailability);
            });
    });

const setProviderUnavailability = async (providerId: string, dispatch) => {
    const data = await getProviderUnavailability(providerId);
    dispatch({
        type: 'SELECT-PROVIDER-UNAVAILABILITY',
        payload: data,
    });
    dispatch({ type: 'SET-FILTERED-OPENINGS', payload: dayjs().toISOString() });
    return data;
};

export const Providers = () => {
    const {
        dispatch,
        store: { selectedAppointment, selectedProvider },
    } = useStore(Store);

    const { data: filteredProviders } = useQuery(['providers', selectedAppointment?.id], async () => {
        const providersResponse = await CustomAxios.get('schedule/api/v2/providers');
        const providersData =
            providersResponse.data?.providers?.map((provider) => ({
                ...provider,
                name: `${provider.firstName} ${provider.lastName}`,
            })) || [];

        const availableProviders = providersData.filter(({ id }) => {
            return !!selectedAppointment?.providerAssets?.find(
                (providerAsset) => providerAsset.id === id && providerAsset.available
            );
        });

        dispatch({
            type: 'SET-ALL-PROVIDERS',
            payload: availableProviders,
        });
        return availableProviders;
    });

    useEffect(() => {
        if (filteredProviders?.length === 1) {
            selectProvider(filteredProviders[0].id);
        }
    }, [filteredProviders?.length]);

    const selectedProviderId = useMemo(() => {
        return typeof selectedProvider === 'string' ? selectedProvider : selectedProvider?.id;
    }, [selectedProvider]);

    const providerSelectProps = useFormField(
        {
            type: 'radio',
            value: selectedProviderId,
        },
        [selectedProviderId]
    );

    useEffect(() => {
        selectProvider(providerSelectProps.value);
        // eslint-disable-next-line
    }, [providerSelectProps.value]);

    useEffect(() => {
        if (selectedProvider.id !== '') {
            setProviderUnavailability(selectedProvider.id, dispatch);
        }
    }, [selectedProvider]);

    const selectProvider = (payload: string) => {
        dispatch({ type: 'SELECT-PROVIDER', payload });
        dispatch({ type: 'RESET-FILTERED-OPENINGS', payload: selectedAppointment?.id });
        dispatch({ type: 'SET-FILTERED-OPENINGS', payload: dayjs().toISOString() });
    };

    const providers = useMemo(() => {
        if (filteredProviders?.length === 1) {
            return filteredProviders;
        }
        return [defaultProviderSelection, ...(filteredProviders || [])];
    }, [filteredProviders]);

    return (
        <RadioField {...providerSelectProps} name="provider-select" labelPlacement="left" css={itemContainer}>
            {providers?.map(({ name, providerName, publicDisplayName, publicDisplayImage, id }, i) => {
                providerName = publicDisplayName || providerName || name;
                return (
                    <NakedButton
                        key={id}
                        value={id}
                        css={providerItem({ id, value: providerSelectProps.value })}
                        onClick={(e: any) => providerSelectProps.onChange(e)}
                    >
                        {id === 'all' ? (
                            // first option should always be an icon
                            <FamilyIcon css={avatarContainer} size={48} color="white" />
                        ) : publicDisplayImage ? (
                            // if there's an image for the provider, show it
                            <img src={publicDisplayImage} css={imgStyles} alt={providerName} />
                        ) : (
                            // if not, show this avatar
                            <UserIcon css={avatarContainer} size={48} color="white" />
                        )}
                        {providerName}
                    </NakedButton>
                );
            })}
        </RadioField>
    );
};
