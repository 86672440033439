import React from 'react';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import { widthMaxQuery, widthMinQuery } from './display-switch';

type ContainerProps = {
    hasBorder: boolean;
    fullHeight: boolean;
};

const container = ({ hasBorder, fullHeight }: ContainerProps) => [
    css`
        border-radius: ${theme.borderRadius.medium};
        width: 100%;
        max-width: 600px;
        height: 100%;
        height: calc(100vh - 163px);
        ${fullHeight && 'max-height: calc(100vh - 150px);'}
        background-color: ${theme.colors.white};
        overflow-y: auto;
        padding: ${theme.spacing(2)};
        ${widthMaxQuery(`
            max-width:100%;
            box-shadow: none;
        `)}
    `,
    hasBorder &&
        css`
            ${widthMinQuery(`
                border: 1px solid ${theme.colors.gray400};
            `)}
        `,
];

export const WizardCard = ({ children, hasBorder = false, fullHeight = false }) => (
    <section css={container({ hasBorder, fullHeight })}>{children}</section>
);
