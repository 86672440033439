import React from 'react';
import { css } from '@emotion/core';
import { WizardButtons } from './components/wizard-buttons';
import { WizardCard } from './components/wizard-card';
import { WizardFooter } from './components/wizard-footer';
import { WizardHeader } from './components/wizard-header';
import { WizardMain } from './components/wizard-main';

const wizardPageContainer = css`
    position: relative;
    height: 100vh;
`;

export const WizardPage = ({ children }: { children: React.ReactNode }) => (
    <div css={wizardPageContainer}>{children}</div>
);

WizardPage.Buttons = WizardButtons;
WizardPage.Card = WizardCard;
WizardPage.Footer = WizardFooter;
WizardPage.Header = WizardHeader;
WizardPage.Main = WizardMain;
