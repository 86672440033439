import { Text, XIcon, IconButton } from '@weave/design-system';
import { theme } from '@weave/theme-original';
import { css } from '@emotion/core';
import React from 'react';
import dayjs from 'dayjs';
import { convertToMinutes } from '../date-time-helpers';
import { OpeningV2, StoreActions } from '../../../../context/store.model';

type TimeSelectionProps = {
    opening: OpeningV2;
    idx: number;
    dispatch: React.Dispatch<StoreActions>;
};

const formatDate = (time: string) => dayjs(time).format('d, MMM D, YYYY');

export const TimeSelection = ({ opening, idx, dispatch }: TimeSelectionProps) => (
    <article
        css={css`
            border: 1px solid ${theme.colors.gray400};
            border-radius: ${theme.borderRadius.small};
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: ${theme.spacing(2)};
            margin-bottom: ${theme.spacing(1)};
        `}
        key={opening?.start + idx}
    >
        <div>
            <Text css={{ margin: 0 }}>{formatDate(opening?.start)}</Text>
            <Text css={{ margin: 0 }} color="light">
                {convertToMinutes(opening?.duration as number)} minute appointment.
            </Text>
        </div>
        <IconButton label="Remove time" onClick={() => dispatch({ type: 'REMOVE-SELECTED-OPENING', payload: opening })}>
            <XIcon size={16} />
        </IconButton>
    </article>
);
