import React from 'react';
import { css } from '@emotion/core';
import { SerializedStyles } from '@emotion/css';

type Size = 'small' | 'large';

type Props = {
    children: any;
    sizes: Size[];
};

const breakpoint = 750;

export const widthMaxQuery = (styles: SerializedStyles | string) =>
    css`
        @media (max-width: ${breakpoint}px) {
            ${styles}
        }
    `;

export const widthMinQuery = (styles: SerializedStyles | string) =>
    css`
        @media (min-width: ${breakpoint}px) {
            ${styles}
        }
    `;

const container = (sizes: Size[]) =>
    !sizes.includes('small')
        ? widthMaxQuery(`display: none;`)
        : !sizes.includes('large') && widthMinQuery(`display: none;`);

export const DisplaySwitch = ({ children, sizes }: Props) => <div css={container(sizes)}>{children}</div>;
