import { Heading, Text, PrimaryButton } from '@weave/design-system';
import { css } from '@emotion/core';
import { WizardPage } from '../generic/wizard-page';
import { PoweredByWeave } from '../generic/components/powered-by-weave.component';
import { useStore } from '../context/use-store.hook';
import { Store } from '../context/store';
import { useHistory } from 'react-router-dom';
import { WizardFlows, wizardFirstSteps } from '../generic/wizard.types';

export const BadRoute = () => {
    const {
        dispatch,
        store: {
            config: { locationId },
        },
    } = useStore(Store);
    const history = useHistory();

    const startOver = () => {
        dispatch({ type: 'RESET-INITIAL-STORE' });
        history.push(`/${locationId}/${WizardFlows?.Configuration}/${wizardFirstSteps?.[WizardFlows?.Configuration]}`);
    };

    return (
        <WizardPage>
            <WizardPage.Header>
                <div
                    css={css`
                        width: 100%;
                        display: flex;
                        align-items: flex-start;
                        max-width: 600px;
                        flex-direction: column;
                    `}
                >
                    <Heading css={{ marginBottom: 0 }}>Error</Heading>
                    <Text color="error" css={{ margin: 0 }}>
                        Something didn't work ... please try again.
                    </Text>
                </div>
            </WizardPage.Header>
            <WizardPage.Main>
                <section
                    css={css`
                        width: 100%;
                        display: flex;
                        align-items: flex-start;
                        max-width: 600px;
                        flex-direction: column;
                    `}
                >
                    <Text>Click the button below to start over.</Text>
                    <br />
                    <PrimaryButton size="tiny" onClick={startOver}>
                        Start over
                    </PrimaryButton>
                </section>
            </WizardPage.Main>
            <PoweredByWeave />
        </WizardPage>
    );
};
