import React from 'react';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import { widthMaxQuery } from './display-switch';

export const WizardFooter = ({ children }: { children: React.ReactNode }) => (
    <footer
        css={css`
            display: flex;
            justify-content: flex-end;
            border-top: 1px solid ${theme.colors.gray300};
            position: fixed;
            width: 100%;
            bottom: 0;
            z-index: 100;
            padding: ${theme.spacing(1)};
            background: ${theme.colors.white};
            ${widthMaxQuery(`justify-content: space-between;`)}
        `}
    >
        {children}
    </footer>
);
