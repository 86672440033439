import React, { useEffect, useState } from 'react';
import { Store } from '../../../context/store';
import { useStore } from '../../../context/use-store.hook';
import { DropdownField, SpinningLoader, Text, useFormField } from '@weave/design-system';
import { theme } from '@weave/theme-original';
import { css } from '@emotion/core';
import { ProviderAssetsOnApptType, ProviderV2Asset, ProvidersWithImage } from '../../../context/store.model';
import { getApptTypes } from '../../../wizards/wizard-helpers';
import { useQuery } from 'react-query';
import { CustomAxios } from '../../../custom-axios';

type MergeProvidersArgs = {
    withImg: ProviderV2Asset[];
    withoutImg: ProviderAssetsOnApptType[];
};

const anyProvider: ProviderAssetsOnApptType = {
    uuid: { uuid: '' },
    id: { uuid: 'all', bytes: '' },
    appointmentTypeID: { uuid: '', bytes: '' },
    providerName: 'Any',
    available: true,
};

export const AppointmentSelect = () => {
    const {
        dispatch,
        store: {
            selectedAppointment,
            selectedProvider,
            appointments,
            activeLocation: { locationId },
        },
    } = useStore(Store);
    const rbd = Number(selectedAppointment?.requestBufferDuration) || 0;
    const [providers, setProviders] = useState<ProviderAssetsOnApptType[]>([]);

    const { data: allProviders } = useQuery(['providers'], async () => {
        const providersResponse = await CustomAxios.get('schedule/api/v2/providers');
        const providersData =
            providersResponse.data.providers?.map((provider) => ({
                ...provider,
                name: `${provider.firstName} ${provider.lastName}`,
            })) || [];
        dispatch({
            type: 'SET-ALL-PROVIDERS',
            payload: providersData,
        });
        return providersData;
    });
    const [loading, setLoading] = useState(true);
    const [errorState, setErrorState] = useState('');
    const apptSelectProps = useFormField({
        type: 'dropdown',
        value: selectedAppointment?.id,
    });

    const mergeProviderObjects = ({ withImg, withoutImg }: MergeProvidersArgs) => {
        const providerPicsById = {};
        const providerPicsByResourceName = {};

        withImg?.forEach(({ publicDisplayImage, resourceName, id }: ProvidersWithImage) => {
            providerPicsByResourceName[resourceName] = publicDisplayImage;
            providerPicsById[id] = publicDisplayImage;
        });

        const filteredProvidersWithImages = [anyProvider, ...withoutImg].map((provider) => ({
            ...provider,
            imgUrl: providerPicsById[provider?.id?.uuid] ?? providerPicsByResourceName[provider?.providerName],
        }));

        dispatch({ type: 'SET-FILTERED-PROVIDERS', payload: filteredProvidersWithImages });
        return filteredProvidersWithImages;
    };

    const setAppointment = (apptID: string) => {
        const appt = appointments?.find((item) => item?.id === apptID);
        if (appt !== undefined) {
            dispatch({ type: 'SELECTED-APPOINTMENT', payload: appt });
            dispatch({ type: 'RESET-SELECTED-OPENING' });
        }
    };

    useEffect(() => {
        if (appointments?.length) {
            setLoading(false);
            setErrorState('');
        }
    }, [appointments?.length]);

    useEffect(() => {
        const availableProviders = selectedAppointment?.providerAssets?.filter((provider) => provider?.available);
        setProviders([
            ...mergeProviderObjects({
                withImg: allProviders,
                withoutImg: availableProviders,
            }),
        ]);
    }, [selectedAppointment?.providerAssets, selectedAppointment, allProviders]);

    useEffect(() => {
        if (apptSelectProps?.value) {
            setAppointment(apptSelectProps?.value);
        }
    }, [rbd, apptSelectProps?.value, JSON.stringify(selectedProvider)]);

    useEffect(() => {
        if (locationId && !appointments?.length) {
            getApptTypes({ locationId, dispatch });
        }
    }, [locationId, appointments?.length]);

    useEffect(() => {
        // if the request was successful and the appointments array is empty, show an error state
        if (!loading && appointments?.length === 0) {
            setErrorState('No appointments configured for this location');
        }
    }, [loading, appointments?.length]);

    return (
        <div
            css={[
                (!!loading || errorState !== '') &&
                    css`
                        margin-top: ${theme.spacing(1)};
                        text-align: center;
                        overflow: visible;
                    `,
            ]}
        >
            {loading && appointments?.length === 0 && <SpinningLoader />}
            {errorState ? (
                <Text textAlign="center" color="warn">
                    {errorState}
                </Text>
            ) : (
                <DropdownField {...apptSelectProps} name="appt-select" label="Reason for appointment">
                    {appointments?.map(({ id, name }) => (
                        <DropdownField.Option key={id} value={id}>
                            {name}
                        </DropdownField.Option>
                    ))}
                </DropdownField>
            )}
        </div>
    );
};
