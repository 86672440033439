import React, { useMemo } from 'react';
import { WizardPage } from '../../generic/wizard-page';
import { AppointmentRequestHeader } from './wizard/appointment-request-header';
import { DisplaySwitch } from '../../generic/components/display-switch';
import { Details } from './wizard/details.component';
import { useRequestOpening } from '../../hooks/resources/use-request-openings.hooks';
import { WizardControlFn, WizardStep } from '../../generic/wizard.types';
import { useWizard } from '../../hooks/use-wizard';
import { AppointmentSelect } from './appointment-select/appointment-select.component';
import { DateTimeSelect } from './date-time-select/date-time-select.component';
import { PersonInfoForm } from './person-info-form/person-info-form.component';
import { Providers } from './providers-select/providers';
import { SuccessMessage } from './success-message/success-message.component';
import { useStore } from '../../context/use-store.hook';
import { Text } from '@weave/design-system';
import { theme } from '@weave/theme-original';
import { Store } from '../../context/store';
import { ProviderInfo, StoreState } from '../../context/store.model';

const appointmentRequestWizard = {
    'select-appt-type': {
        Component: AppointmentSelect,
        currentSelection: (store: StoreState) => store?.selectedAppointment?.name,
        title: 'Choose an appointment',
        validation: (store: StoreState) => !!store?.selectedAppointment?.id && !!store?.selectedAppointment?.name,
    },
    providers: {
        Component: Providers,
        currentSelection: (store: StoreState) =>
            typeof store.selectedProvider === 'string' ? store.selectedProvider : store.selectedProvider?.id,
        title: 'Choose a provider',
        selectionLabel: (store: StoreState) => {
            return store.selectedProvider?.publicDisplayName || store.selectedProvider?.name;
        },
        validation: (store: StoreState) => {
            const valid =
                typeof store.selectedProvider === 'string' ? !!store.selectedProvider : !!store.selectedProvider?.id;
            return valid;
        },
    },
    'select-date-time': {
        Component: DateTimeSelect,
        currentSelection: (store: StoreState) =>
            `${store.selectedOpening.length}/${store.appointmentConfig.availableSlots} selected`,
        title: 'Choose a date and time',
        validation: (store: StoreState) => store?.selectedOpening?.length === store?.appointmentConfig?.availableSlots,
    },
    'contact-info': {
        Component: PersonInfoForm,
        currentSelection: () => null,
        nextLabel: 'Submit',
        onNextAction: 'SUBMIT',
        title: 'Enter your info',
        validation: (store) => store.personInfo.isComplete,
    },
    success: {
        Component: SuccessMessage,
        currentSelection: () => null,
        backLabel: 'Close',
        nextLabel: 'Restart',
        onBackAction: 'CLOSE',
        onNextAction: 'RESTART',
        title: 'Success',
        validation: () => true,
    },
} as const;

type FooterStatusArgs = {
    title: WizardStep['title'] | boolean;
    selection: string | ProviderInfo | null;
    label?: string;
};

const footerStatus = ({ title, selection, label }: FooterStatusArgs) => {
    switch (title) {
        case title === 'Enter your info':
        case title === 'Success':
            return '';
        case !title:
            return 'None selected';
        default:
            return label || selection;
    }
};

export const AppointmentRequestWizard = () => {
    const { store } = useStore(Store);
    const submitRequest = useRequestOpening();

    const handleSubmit: WizardControlFn = async () => {
        try {
            const res = await submitRequest('appointment');
            return !!res;
        } catch (error) {
            console.error(error);
            return false;
        }
    };

    const { step, wizardPaths, wizardControl, current } = useWizard({
        wizard: appointmentRequestWizard,
        type: 'appointment-requests',
        controls: {
            SUBMIT: handleSubmit,
        },
    });

    const { Component, currentSelection, selectionLabel } = current;
    const selection = currentSelection ? currentSelection(store) : '';
    const label = useMemo(() => {
        return selectionLabel?.(store) || '';
    }, [selectionLabel, store]);

    return (
        <WizardPage>
            <WizardPage.Header>
                <AppointmentRequestHeader
                    wizardControl={wizardControl}
                    step={step}
                    wizardPaths={wizardPaths}
                    current={current}
                />
            </WizardPage.Header>

            <WizardPage.Main>
                <WizardPage.Card hasBorder={step === 'select-date-time' || step === 'success'} fullHeight={true}>
                    {typeof Component === 'function' && <Component />}
                </WizardPage.Card>
                <article css={{ height: 'min-content' }}>
                    <DisplaySwitch sizes={['large']}>
                        <WizardPage.Card min-width="120px">
                            <Details />
                        </WizardPage.Card>
                    </DisplaySwitch>
                </article>
            </WizardPage.Main>

            <WizardPage.Footer>
                <DisplaySwitch sizes={['small']}>
                    <Text css={{ marginLeft: theme.spacing(2) }} color={selection ? 'default' : 'light'}>
                        {footerStatus({ title: current?.title, selection, label })}
                    </Text>
                </DisplaySwitch>
                <WizardPage.Buttons
                    step={step}
                    wizardPaths={wizardPaths}
                    current={current}
                    wizardControl={wizardControl}
                />
            </WizardPage.Footer>
        </WizardPage>
    );
};
