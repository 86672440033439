import React, { createContext, useReducer, useEffect, Reducer } from 'react';
import dayjs from 'dayjs';
import { getParameterByName, setBaseURL, setHeader } from '../custom-axios';
import { Config, ContextInterface, StoreActions, StoreState } from './store.model';
import { initialState } from './store-defaults';
import { getStatusList } from '../hooks/resources/openings-utils';
import { filterOpeningsByProvider } from '../wizards/appointment-request/date-time-select/date-time-helpers';
import { monthFormat } from '../hooks/resources/use-openings.hook';

const Store = createContext<ContextInterface>({
    storeState: { ...initialState },
    storeDispatch: () => {
        return;
    }, // This will be updated by the reducer
});

function reducer(state: StoreState, action: StoreActions): StoreState {
    switch (action.type) {
        case 'RESET-INITIAL-STORE':
            return {
                ...initialState,
                config: state.config,
                activeLocation: { ...initialState.activeLocation, locationId: state.config.locationId },
                wizardOptions: state.wizardOptions,
                appointments: state.appointments,
                appointmentConfig: state.appointmentConfig,
                isFullScreen: state.isFullScreen,
            };
        case 'LOCATION-ID':
            return {
                ...state,
                activeLocation: {
                    ...state.activeLocation,
                    locationId: action.payload,
                },
            };
        case 'ACTIVE-LOCATION':
            if (action.payload?.locationId) {
                setHeader('Location-Id', action.payload?.locationId);
            }
            return {
                ...state,
                activeLocation: {
                    ...action.payload,
                    locationId: action.payload?.locationId,
                },
            };
        case 'SET-LOCATIONS':
            return { ...state, locations: action.payload };
        case 'SET-FILTERED-PROVIDERS':
            return { ...state, filteredProviders: action.payload };
        case 'RESET-APPOINTMENT-TYPES':
            return { ...state, appointments: [] };
        case 'APPOINTMENT-TYPES':
            return {
                ...state,
                appointments: action.payload,
            };
        case 'UPDATE-DISABLED-FEATURES':
            return {
                ...state,
                config: {
                    ...state.config,
                    disabledFeatures: action.payload,
                },
            };
        case 'SELECTED-OPENING':
            let selectedOpening = state.selectedOpening;
            const selectedOpeningIndex = selectedOpening.findIndex((item) => item.start === action.payload.start);
            if (state.appointmentConfig.availableSlots > 1) {
                if (selectedOpeningIndex > -1) {
                    selectedOpening = selectedOpening.filter((item, idx) => idx !== selectedOpeningIndex);
                } else {
                    if (selectedOpening.length < state.appointmentConfig.availableSlots) {
                        selectedOpening = [...selectedOpening, action.payload];
                    }
                }
            } else {
                selectedOpening = [action.payload];
            }
            selectedOpening.sort((a, b) => a.start.localeCompare(b.start));
            return {
                ...state,
                selectedOpening: [...selectedOpening],
            };
        case 'REMOVE-SELECTED-OPENING':
            return {
                ...state,
                selectedOpening: state.selectedOpening.filter((opening) => opening.start !== action.payload.start),
            };
        case 'RESET-SELECTED-OPENING':
            return { ...state, selectedOpening: [] };
        case 'SELECTED-APPOINTMENT':
            const apptType = action.payload;
            const availableSlots = Number(apptType?.minOpeningsPermitted || 1) ?? 1;
            const requestBufferDuration = Number(apptType?.requestBufferDuration ?? 0);
            return {
                ...state,
                selectedAppointment: {
                    ...state.selectedAppointment,
                    ...action.payload,
                },
                appointmentConfig: {
                    requestBufferDuration,
                    availableSlots: !availableSlots ? 1 : availableSlots,
                },
                selectedProvider: {
                    id: '',
                    name: '',
                },
            };
        case 'PERSON-INFO':
            return { ...state, personInfo: { ...state.personInfo, ...action.payload } };
        case 'SELECT-PROVIDER':
            const newSelectedProvider = state.allProviders?.find((provider) => provider.id === action.payload) || {
                id: 'all',
                name: 'Any Provider',
                publicDisplayName: 'Any Provider',
            };
            return { ...state, selectedProvider: newSelectedProvider };
        case 'SELECT-PROVIDER-UNAVAILABILITY':
            return {
                ...state,
                selectedProviderUnavailability: action.payload,
            };
        case 'SET-OPENINGS':
            const { appointmentID, openings } = action.payload;
            return {
                ...state,
                openings: {
                    ...state.openings,
                    [appointmentID]: {
                        ...(state?.openings?.[appointmentID] ?? {}),
                        ...openings,
                    },
                },
            };
        case 'RESET-FILTERED-OPENINGS':
            return {
                ...state,
                filteredOpenings: {
                    [action.payload]: {
                        openings: {},
                        closed: [],
                    },
                },
            };
        case 'SET-FILTERED-OPENINGS': {
            const dateVal = dayjs(action.payload);
            const monthKey = dateVal.format(monthFormat);
            const apptID = state.selectedAppointment?.id;
            const openings = Object.entries(state?.openings?.[apptID] ?? {}).reduce(
                (prev, [dateKey, openingsArray]) => {
                    if (dayjs(dateKey).format(monthFormat) === monthKey) {
                        return {
                            ...prev,
                            // only return openings that are not in the past
                            [dateKey]: openingsArray.filter(({ start }) => !dayjs(start).isBefore(dayjs())),
                        };
                    }
                    return prev;
                },
                {}
            );
            const filteredOpenings = filterOpeningsByProvider({
                openings,
                provider: state.selectedProvider,
                providerUnavailability: state.selectedProviderUnavailability,
            });
            const start = dateVal.startOf('month');
            const end = dateVal.endOf('month');
            const { closed } = getStatusList({ openings: filteredOpenings, start, end });
            return {
                ...state,
                filteredOpenings: {
                    ...state.filteredOpenings,
                    [apptID]: {
                        openings: { ...filteredOpenings },
                        closed: [...closed],
                    },
                },
            };
        }
        case 'SET-FULL-SCREEN':
            return {
                ...state,
                isFullScreen: action.payload,
            };
        case 'SET-ALL-PROVIDERS':
            return {
                ...state,
                allProviders: action.payload,
            };
        default:
            return { ...state };
    }
}

export interface StoreInitialProps {
    config: Config;
    initState?: StoreState;
    children: JSX.Element;
}

function StoreProvider(props: StoreInitialProps) {
    const [storeState, storeDispatch] = useReducer<Reducer<StoreState, StoreActions>>(reducer, {
        ...initialState,
        ...props.initState,
        config: props.config,
    });
    const value = { storeState, storeDispatch };
    const { locationId, resourceAPI } = storeState?.config ?? {};

    useEffect(() => {
        if (locationId || getParameterByName('wid')) {
            const newLocationID = getParameterByName('wid') ?? locationId;
            storeDispatch({ type: 'LOCATION-ID', payload: newLocationID });
            setBaseURL(locationId, resourceAPI);
        }
    }, [locationId, resourceAPI]);

    return <Store.Provider value={value}>{props.children}</Store.Provider>;
}

const StoreConsumer = Store.Consumer;

export { Store, StoreProvider, StoreConsumer };
