import React, { useEffect, useState } from 'react';
import { theme } from '@weave/theme-original';
import { ContentLoader, NakedButton, RadioField, useFormField } from '@weave/design-system';
import { css } from '@emotion/core';
import { useStore } from '../../context/use-store.hook';
import { Store } from '../../context/store';
import { LocationConfig } from '../../context/store.model';
import { Features, wizardFirstSteps, WizardFlows } from '../../generic/wizard.types';
import { CustomAxios } from '../../custom-axios';
import { useHistory } from 'react-router-dom';
import { getApptTypes } from '../wizard-helpers';

export const LocationSelect = () => {
    const history = useHistory();
    const { dispatch, store } = useStore(Store);
    const [loading, setLoading] = useState(false);
    const locationSelectProps = useFormField({ type: 'radio', value: store?.activeLocation?.displayName });

    const handleConfig = async () => {
        const locations = store?.config?.assistant?.locationConfigurations;
        let enabled: LocationConfig[] = [];
        for (const location of locations) {
            enabled?.push(location);
        }

        dispatch({ type: 'SET-LOCATIONS', payload: enabled });

        if (enabled?.length === 1) {
            dispatch({
                type: 'ACTIVE-LOCATION',
                payload: enabled[0],
            });
        }
        setLoading(false);
    };

    useEffect(() => {
        if (!!CustomAxios?.defaults?.baseURL) {
            handleConfig();
        }
        // eslint-disable-next-line
    }, [CustomAxios.defaults.baseURL]);

    const nextWizard = async (location: LocationConfig) => {
        const { locationId, disabledFeatures } = location;

        dispatch({ type: 'UPDATE-DISABLED-FEATURES', payload: disabledFeatures });
        // if store.appointments
        // filter through those appointments
        const hasConfiguredAppointments = (await getApptTypes({ locationId, dispatch })).length > 0;
        // if OnlineSchedulingFeature is disabled,
        // or the location has no configured appointments,
        // navigate straight to TextConnect Flow
        if (disabledFeatures.includes(Features?.OnlineScheduling) || !hasConfiguredAppointments) {
            history.push(`/${locationId}/${WizardFlows?.TextConnect}/${wizardFirstSteps?.[WizardFlows?.TextConnect]}`);
            return;
        }
        // if WebContactFeature is disabled, navigate straight to AppointmentRequests Flow
        if (disabledFeatures.includes(Features?.WebContact)) {
            history.push(
                `/${locationId}/${WizardFlows?.AppointmentRequests}/${
                    wizardFirstSteps?.[WizardFlows?.AppointmentRequests]
                }`
            );
            return;
        } else {
            // if both features are enabled, just go to the next step
            history.push(`/${locationId}/configuration/select-app`);
        }
    };

    return loading ? (
        <ContentLoader />
    ) : (
        <RadioField {...locationSelectProps} name="location-select" css={{ minWidth: 480 }}>
            {store.locations.map((location) => (
                <NakedButton
                    css={[
                        css`
                            display: flex;
                            flex-direction: column;
                            border: 1px solid ${theme.colors.gray400};
                            min-height: 72px;
                            width: 100%;
                            margin: ${theme.spacing(2, 0)};
                            padding: ${theme.spacing(2)};
                            justify-content: center;
                            border-radius: ${theme.borderRadius.medium};
                        `,
                        store?.activeLocation?.displayName === location?.displayName &&
                            css`
                                border: 1px solid ${theme.colors.weaveBlue};
                            `,
                    ]}
                    key={location?.locationId}
                    onClick={() => {
                        dispatch({
                            type: 'ACTIVE-LOCATION',
                            payload: location,
                        });
                        dispatch({
                            type: 'RESET-APPOINTMENT-TYPES',
                        });
                        nextWizard(location);
                    }}
                >
                    {location?.displayName}
                </NakedButton>
            ))}
        </RadioField>
    );
};
