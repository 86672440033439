import { WizardFlows } from '../generic/wizard.types';
import {
    AppointmentTypeV2,
    AssistantConfig,
    LocationConfig,
    AppointmentConfig,
    Config,
    PersonInfo,
    StoreState,
    ProviderInfo,
    ProviderUnavailability,
} from './store.model';

const assistant: AssistantConfig = {
    id: '',
    name: '',
    locationConfigurations: [],
    locationId: '',
};
const apptTypes: AppointmentTypeV2[] = [];

const initConfig: Config = {
    locationId: '',
    resourceAPI: '',
    captchaSiteKey: '',
    locationName: '',
    linkHash: '',
    linkDurationMinutes: 15,
    embeddedWidget: false,
    assistant,
    appointmentTypes: apptTypes,
    disabledFeatures: [],
    autoAppSelect: 'both',
};

const initApptConfig: AppointmentConfig = {
    requestBufferDuration: 0,
    availableSlots: 3,
};

const initSelectedAppointment: AppointmentTypeV2 = {
    durationMinutes: '15', // A default of 15 prevents errors in our exploder
    id: '',
    locationId: '',
    name: '',
    operatoryAssets: [],
    providerAssets: [],
    cadence: null,
    maxOpeningsPermitted: 1,
    minOpeningsPermitted: 1,
    requestBufferDuration: '0',
};

const initPersonInfo: PersonInfo = {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    note: '',
    isComplete: false,
};

const defaultSelectedProvider: ProviderInfo = {
    id: '',
    name: '',
};

export const defaultSelectedProviderUnavailability: ProviderUnavailability = {
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
};

const activeLocation: LocationConfig = {
    locationId: '',
    displayName: '',
    disabledFeatures: [],
};

export const initialState: StoreState = {
    config: initConfig,
    appointmentConfig: initApptConfig,
    formRequestStep: 0,
    locationId: '',
    appointments: [],
    wizardComponents: [],
    selectedAppointment: initSelectedAppointment,
    selectedOpening: [],
    openings: {},
    filteredOpenings: {},
    personInfo: initPersonInfo,
    requestResponse: {
        message: '',
        status: '',
    },
    requestFormVisible: false,
    wizardOptions: [WizardFlows.TextConnect],
    selectedWizard: '',
    isFullScreen: false,
    activeLocation,
    locations: [],
    selectedProvider: defaultSelectedProvider,
    selectedProviderUnavailability: defaultSelectedProviderUnavailability,
    allProviders: [],
    filteredProviders: [],
};
