import { ProviderInfo, StoreState } from '../context/store.model';

export type WizardStep = {
    Component: React.ReactNode;
    title: string | ((store: StoreState) => string);
    validation: (store: StoreState) => boolean;
    onNextAction?: 'RESTART' | 'CLOSE' | string;
    onBackAction?: 'RESTART' | 'CLOSE' | string;
    nextLabel?: string;
    backLabel?: string;
    currentSelection?: (store: StoreState) => string | ProviderInfo | null;
    selectionLabel?: (store: StoreState) => string | null;
};

type shouldContinue = boolean;

export type Steps =
    | 'select-appt-type'
    | 'select-location'
    | 'providers'
    | 'select-date-time'
    | 'contact-info'
    | 'success'
    | 'Location:'
    | '';

export enum Features {
    WebContact = 'WEB_ASSISTANT_FEATURE_WEB_CONTACT',
    OnlineScheduling = 'WEB_ASSISTANT_FEATURE_ONLINE_SCHEDULING',
}

export enum WizardFlows {
    AppointmentRequests = 'appointment-requests',
    Configuration = 'configuration',
    TextConnect = 'text-connect',
}

export const wizardFirstSteps = {
    'appointment-requests': 'select-appt-type',
    configuration: 'select-app',
    'text-connect': 'contact-info',
} as const;

export type Wizards = keyof typeof wizardFirstSteps | '';

export type WizardControlFn = (controlType?: any) => shouldContinue | Promise<shouldContinue>;
