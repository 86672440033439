import { NakedButton, Text } from '@weave/design-system';
import { useHistory } from 'react-router-dom';
import { Calendar, Cellphone } from '../../images';
import { theme } from '@weave/theme-original';
import { css } from '@emotion/core';
import { Store } from '../../context/store';
import { useStore } from '../../context/use-store.hook';
import { Features, wizardFirstSteps, WizardFlows, Wizards } from '../../generic/wizard.types';
import { useCallback, useEffect } from 'react';

const cardContainer = css`
    --size: ${theme.spacing(4)};
    width: calc(100% - var(--size));
    height: 100%;
    max-width: 600px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
const optionsContainer = css`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const cardOption = css`
    display: flex;
    align-items: center;
    margin: ${theme.spacing(2, 3)};
    padding: ${theme.spacing(2, 3)};
    border-radius: ${theme.borderRadius.medium};
    border: 1px solid ${theme.colors.gray400};
    width: calc(100% - ${theme.spacing(2)});
    max-width: 480px;
    min-width: 300px;
    cursor: pointer;
    @media only screen and (max-width: 600px) {
        padding: ${theme.spacing(2, 2)};
    }
`;
const iconStyle = css`
    width: 72px;
    height: 72px;
    @media only screen and (max-width: 600px) {
        width: 64px;
        width: 72px;
    }
`;

const cardOptionTextWrapper = css`
    width: 100%;
    p {
        margin: 0;
        padding-left: ${theme.spacing(3)};
        :first-of-type {
            font-size: ${theme.fontSize(24)};
            @media only screen and (max-width: 600px) {
                font-size: ${theme.fontSize(18)};
            }
        }
        :last-of-type {
            font-size: ${theme.fontSize(16)};
            @media only screen and (max-width: 600px) {
                font-size: ${theme.fontSize(14)};
            }
        }
    }
`;

export const FlowSelect = () => {
    const history = useHistory();
    const {
        store: {
            activeLocation: { locationId, disabledFeatures },
            appointments,
            config: { autoAppSelect },
        },
    } = useStore(Store);

    const selectWizardFlow = useCallback(
        (wizard: Wizards) => {
            history.push(`/${locationId}/${wizard}/${wizardFirstSteps?.[wizard]}`);
        },
        [locationId, history]
    );

    const displayWebContact = !disabledFeatures?.includes(Features?.WebContact);
    const displayOnlineScheduling = !disabledFeatures?.includes(Features?.OnlineScheduling) && !!appointments?.length;

    useEffect(() => {
        if (autoAppSelect === 'textConnect') {
            selectWizardFlow(WizardFlows.TextConnect);
        } else if (autoAppSelect === 'onlineScheduling') {
            selectWizardFlow(WizardFlows.AppointmentRequests);
        }
    }, [selectWizardFlow, autoAppSelect]);

    return (
        <div css={cardContainer}>
            <div css={optionsContainer}>
                {displayWebContact && (
                    <NakedButton css={cardOption} onClick={() => selectWizardFlow(WizardFlows.TextConnect)}>
                        <Cellphone css={iconStyle} />
                        <div css={cardOptionTextWrapper}>
                            <Text weight="bold" size="large" textAlign="left">
                                Text Us
                            </Text>
                            <Text size="small" textAlign="left">
                                Text number or click to fill out our contact form.
                            </Text>
                        </div>
                    </NakedButton>
                )}
                {displayOnlineScheduling && (
                    <NakedButton css={cardOption} onClick={() => selectWizardFlow(WizardFlows.AppointmentRequests)}>
                        <Calendar css={iconStyle} />
                        <div css={cardOptionTextWrapper}>
                            <Text weight="bold" size="large" textAlign="left">
                                Request Appointment
                            </Text>
                            <Text size="small" textAlign="left">
                                Use our online scheduling tool to find a time that works for you.
                            </Text>
                        </div>
                    </NakedButton>
                )}
            </div>
        </div>
    );
};
