import { AppointmentTypeV2 } from './../context/store.model';
import { CustomAxios } from '../custom-axios';

export const transformApptTypes = (data: AppointmentTypeV2[]) =>
    data?.map((apptType) => {
        const { requestBufferDuration } = apptType;
        return { ...apptType, requestBufferDuration };
    });

export const getApptTypes = async ({ locationId, dispatch }): Promise<AppointmentTypeV2[] | any> => {
    try {
        const { data } = await CustomAxios.get(`schedule/api/v2/appointment-types`);
        const apptTypes = transformApptTypes(data.data);
        const payload = apptTypes?.filter((apptType: AppointmentTypeV2) => apptType?.locationId === locationId);
        dispatch({ type: 'APPOINTMENT-TYPES', payload });
        return data;
    } catch (err) {
        return err;
    }
};
