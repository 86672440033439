import React from 'react';
import { css } from '@emotion/core';
import { NakedButton, Text } from '@weave/design-system';
import { theme } from '@weave/theme-original';
import { Store } from '../../../../context/store';
import { useStore } from '../../../../context/use-store.hook';
import { OpeningV2 } from '../../../../context/store.model';
import dayjs from 'dayjs';

type OpeningsProps = {
    openings: OpeningV2[];
    category: 'AM' | 'PM';
};

const availableTimeStyle = (selected: OpeningV2 | undefined) => [
    css`
        font-size: ${theme.fontSize(14)};
        border-radius: ${theme.borderRadius.small};
        width: calc(33.33% - ${theme.spacing(2)});
        margin: ${theme.spacing(1)};
        padding: ${theme.spacing(2, 0)};
        border: 1px solid ${theme.colors.gray300};
        color: ${theme.colors.gray600};
        cursor: pointer;
    `,
    selected &&
        css`
            background: ${theme.colors.weaveBlue};
            color: ${theme.colors.white};
            font-weight: bold;
        `,
];

export const Openings = ({ openings, category }: OpeningsProps) => {
    const {
        dispatch,
        store: { selectedOpening },
    } = useStore(Store);
    const isSelected = (opening) => selectedOpening?.find((item) => item?.start === opening?.start);
    const setDateTime = (selected) => dispatch({ type: 'SELECTED-OPENING', payload: selected });

    return !openings?.length ? null : (
        <>
            <Text css={{ marginBottom: theme.spacing(1) }}>{category}</Text>
            <div
                css={css`
                    display: flex;
                    flex-wrap: wrap;
                    max-width: 400px;
                `}
            >
                {openings?.map((opening) => (
                    <NakedButton
                        key={`${opening?.start}_${category}`}
                        data-testid="opening-button"
                        css={availableTimeStyle(isSelected(opening))}
                        onClick={() => setDateTime(opening)}
                    >
                        {dayjs(opening?.start).format('h:mm')}
                    </NakedButton>
                ))}
            </div>
        </>
    );
};
