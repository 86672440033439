import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Store } from '../../../context/store';
import { useStore } from '../../../context/use-store.hook';
import { selectOpenings } from '../../../context/selectors/online-request.selectors';
import { AvailableTimes } from './available-times/available-times.component';
import { css } from 'emotion';
import { useOpenings } from '../../../hooks/resources/use-openings.hook';
import { Calendar } from './month-view/calendar.component';
import { formatSimpleDate, calcStartDate } from './date-time-helpers';
import { Text } from '@weave/design-system';
import { theme } from '@weave/theme-original';
import { Dayjs } from 'dayjs';

export const DateTimeSelect = () => {
    const hasInitialized = useRef(false);
    const {
        store: {
            selectedProvider,
            selectedAppointment: { requestBufferDuration, id },
        },
        selector,
    } = useStore(Store);
    const rbd = Number(requestBufferDuration || 0);
    const scrollRef = useRef<null | HTMLDivElement>(null);
    const startDate = useMemo(() => calcStartDate(rbd || 0), [rbd]);

    const [selectedDate, setSelectedDate] = useState<Dayjs>(startDate);
    const [viewDate, setViewDate] = useState<Dayjs>(startDate);

    const { getOpenings, monthLoader } = useOpenings();
    const { openings, closed } = selector((store) => selectOpenings(store));
    const dayKey = formatSimpleDate(selectedDate);

    const refetchOpenings = useCallback(() => {
        const queryStart = viewDate.startOf('month');
        const queryEnd = queryStart.endOf('month');
        const queryParams = {
            startTime: queryStart.toISOString(),
            endTime: queryEnd.toISOString(),
            appointmentID: id ?? '',
        };
        getOpenings(queryParams);
    }, [getOpenings, viewDate, id]);

    useEffect(() => {
        if (hasInitialized.current) {
            const { current } = scrollRef;
            if (startDate !== selectedDate && current) {
                current?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'start',
                });
            }
        }
        hasInitialized.current = true;
    }, [selectedDate, startDate]);

    useEffect(() => {
        hasInitialized.current = false;
    }, []);

    useEffect(() => {
        refetchOpenings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewDate.toISOString(), JSON.stringify(selectedProvider)]);

    return (
        <div
            className={css`
                position: relative;
                width: 100%;
                height: calc(100vh - 250px);
                .HR {
                    border: none;
                    border-bottom: 1px solid ${theme.colors.gray300};
                    height: 10px;
                }
            `}
        >
            <Calendar
                monthLoader={monthLoader}
                setSelectedDate={setSelectedDate}
                closedDays={closed}
                viewDate={viewDate < startDate ? startDate : viewDate}
                minimumDate={startDate}
                setViewDate={setViewDate}
            />

            <hr className="HR" />

            {viewDate.month() === selectedDate.month() ? (
                <AvailableTimes scrollRef={scrollRef} selectedDate={selectedDate} openings={openings?.[dayKey] ?? []} />
            ) : (
                <Text textAlign="center" color="light" css={{ margin: theme.spacing(2) }}>
                    Select a date from the calendar above to view available times.
                </Text>
            )}
        </div>
    );
};
