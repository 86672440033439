import { useState } from 'react';
import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { GETPromise } from './use-resource.hook';
import { formatOpenings } from './openings-utils';
import { Store } from '../../context/store';
import { CachedOpenings, ISOTime, OpeningV2 } from '../../context/store.model';
import { useStore } from '../../context/use-store.hook';
import { explodeAvailableOpenings } from '../../wizards/appointment-request/date-time-select/date-time-helpers';

const holidayExceptions = [
    '11/24/2022',
    '12/26/2022',
    '12/31/2022',
    '01/1/2023',
    '01/2/2023',
    '01/16/2023',
    '02/20/2023',
    '05/29/2023',
    '06/19/2023',
    '07/04/2023',
    '09/04/2023',
    '11/10/2023',
    '11/11/2023',
    '12/25/2023',
];

export interface QueryParams {
    startTime: ISOTime;
    endTime: ISOTime;
    appointmentID: string;
}

export type OpeningsReturn = {
    getOpenings: (q: QueryParams) => void;
    monthLoader: string[];
};

export const monthFormat = 'MMMM-YYYY';

interface queryConfig {
    url: string;
    appointmentID: string;
    durationMinutes: number;
    cadence: number | null;
    requestBufferDuration: number;
}

export const getResponseData = ({ data }) => (data.hasOwnProperty('data') ? data.data : data);

const fetchOpeningsForMonth = async (start: string, config: queryConfig, exceptions?: string[]) => {
    const { url, appointmentID, durationMinutes: durMins, cadence } = config;
    const startDate = dayjs(start).startOf('month');
    const endDate = startDate.endOf('month');

    const { openings: data } = await GETPromise<AxiosResponse<OpeningV2[]>>(url, {
        start: startDate.toISOString(),
        end: endDate.toISOString(),
        appointmentID,
    }).then(getResponseData);

    const formatted = data
        .filter(({ start }) => {
            return !exceptions || !exceptions.includes(dayjs(start).format('MM/DD/YYYY'));
        })
        .map((item) => ({
            ...item,
            duration: parseInt(item.duration),
        }));

    const durationMinutes = durMins < 5 ? 15 : durMins;
    const explodedOpenings = explodeAvailableOpenings({
        openings: formatted,
        durationMinutes,
        cadence,
    });
    const openings = formatOpenings(explodedOpenings);

    return {
        appointmentID,
        openings,
        start: startDate.format('YYYY/MM/DD hh:dd:ss a'),
        end: endDate.format('YYYY/MM/DD hh:dd:ss a'),
    };
};

const hasOpenings = (time: string, appointmentID: string, openings: CachedOpenings) => {
    const checkMonth = dayjs(time).format(monthFormat);
    return Object.keys(openings?.[appointmentID]?.formatted ?? {}).some(
        (item) => checkMonth === dayjs(item).format(monthFormat)
    );
};

export const useOpenings = (): OpeningsReturn => {
    const {
        dispatch,
        store: {
            openings,
            appointments,
            activeLocation: { locationId },
        },
    } = useStore(Store);

    const [monthLoader, setMonthLoader] = useState<string[]>([]);

    const getAppt = (id: string) => appointments?.find((appt) => appt?.id === id);

    const getOpenings = async ({ startTime: start, endTime: end, appointmentID: apptID }: QueryParams) => {
        if (!locationId) return;
        const url = `schedule/api/v2/appointment-types/${apptID}/openings`;
        const hasCurrentOpenings = hasOpenings(start, apptID, openings);
        const appt = getAppt(apptID);
        const hasNextOpenings = hasOpenings(end, apptID, openings);
        const shouldLoadMore = (hasCurrentOpenings && !hasNextOpenings) || !hasCurrentOpenings;
        const monthKey = dayjs(start).format(monthFormat);
        const isValid = apptID && locationId && !!appt?.durationMinutes;

        if (isValid && shouldLoadMore) {
            const list = [...monthLoader];
            const queryConfig = {
                url,
                appointmentID: apptID,
                durationMinutes: Number(appt?.durationMinutes),
                cadence: Number(appt?.cadence),
                requestBufferDuration: Number(appt?.requestBufferDuration ?? 0),
            };
            try {
                list.every((month) => month !== monthKey) && list.push(monthKey);
                setMonthLoader(list);
                const payload = await fetchOpeningsForMonth(start, queryConfig, holidayExceptions);

                if (payload) {
                    dispatch({ type: 'SET-OPENINGS', payload });
                }
            } catch (err) {
                console.warn("Couldn't load openings.");
            } finally {
                const filteredList = list.filter((item) => item !== monthKey);
                setMonthLoader(filteredList);
            }
        }

        dispatch({ type: 'SET-FILTERED-OPENINGS', payload: start });
    };

    return {
        getOpenings,
        monthLoader,
    };
};
