import { css } from '@emotion/core';
import { Text } from '@weave/design-system';
import { theme } from '@weave/theme-original';
import { mDash } from './details.component';

type DetailProps = {
    category: string;
    children: JSX.Element | JSX.Element[];
    fallback?: string;
};

export const Detail = ({ category, fallback = mDash, children }: DetailProps) => (
    <div
        css={css`
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid ${theme.colors.gray300};
            margin-bottom: ${theme.spacing(2)};
            :last-child {
                border: none;
            }
        `}
    >
        <Text css={{ margin: 0 }} color="light">
            {category}
        </Text>
        {children || fallback}
    </div>
);
