import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import {
  ClosedTime,
  OpeningV2,
  ProviderInfo,
  ProviderUnavailability,
  ScheduleAsset,
  StampedOpenings,
} from '../../../context/store.model';
import { monthFormat } from '../../../hooks/resources/use-openings.hook';

dayjs.extend(isBetween);

export interface ExplodeOpeningsParams {
  durationMinutes: number;
  openings: OpeningV2[];
  cadence: number | null;
}

type ExplodeOpeningsReturn = {
  AM: OpeningV2[];
  PM: OpeningV2[];
};

type FilterOpeningsByProvider = {
  openings: StampedOpenings;
  provider: ProviderInfo;
  providerUnavailability: ProviderUnavailability;
};

type IsUnavailable = {
  opening: OpeningV2;
  providerUnavailabilityDay?: ClosedTime[];
  dateKey: string;
}

export const formatSimpleDate = (date: Dayjs | string) => dayjs(date).format('MM/DD/YYYY');

export const getMinutesTilCadence = (multiplier, start) => (Math.ceil(start / multiplier) * multiplier) - start;

export const distinctStartTimes = (openings: OpeningV2[]) => {
  const result: OpeningV2[] = [];
  const map = new Map();
  for (const opening of openings) {
    if (!map.has(opening.start)) {
      map.set(opening.start, true);
      result.push(opening);
    }
  }
  return result;
};

export const hasOpenings = (openings: StampedOpenings): boolean => Object.keys(openings)?.filter(date => openings[date].length > 0)?.length > 0

export const openingsHasProviderAssets = (openings: StampedOpenings) => {
  const result = Object?.keys(openings)
    ?.some(date => {
      return openings[date]
      ?.filter(opening => opening.assetMap?.providers?.length > 0)
      .length > 0
    }
  )
  return result
}

export const isBlockedOutDay = (day: ClosedTime[]) =>
  day?.map(({durationMinutes}) => durationMinutes)
    .reduce((total, num) => total + num)  === 1440

export const providerIsAvailable = (providerUnavailability: ProviderUnavailability): boolean => {
  const daysUnavailable = Object.keys(providerUnavailability)
    ?.filter(weekday => {
      return providerUnavailability?.[weekday]?.length === 0 || isBlockedOutDay(providerUnavailability[weekday])
    })
  return daysUnavailable?.length < 7
}

export const canWork = (
  provider: ProviderInfo,
  opening: OpeningV2,
) => {
  const isValidProvider = opening?.assetMap?.providers?.find((prov) => {
      if (!prov.id) {
        return prov.name === provider.name || prov.name === provider.publicDisplayName
      }
      return prov.id === provider.id
    })
  return !provider.externalId || !!isValidProvider
}

export const isOpeningConflicting = ({
  opening,
  providerUnavailabilityDay,
  dateKey
}: IsUnavailable): boolean => {
  if (!providerUnavailabilityDay || providerUnavailabilityDay.length === 0) {
    return true
  }
  const startOpening = dayjs(opening.start)
  const endOpening = startOpening.add(+opening.duration, 's')
  const conflictingTimes = providerUnavailabilityDay.filter(({hours, minutes, durationMinutes}) => {
    const start = dayjs(dateKey).hour(hours).minute(minutes)
    const end = start.add(durationMinutes, 'm')
    return startOpening.isBetween(start, end) || endOpening.isBetween(start, end)
  })
  return conflictingTimes.length > 0
}

export const filterOpeningsByProvider = ({ openings, provider, providerUnavailability }: FilterOpeningsByProvider) => {
  if (
    !hasOpenings(openings) ||
    !openingsHasProviderAssets(openings) ||
    !providerIsAvailable(providerUnavailability) || 
    provider.id === 'all' || 
    provider.id === ''
  ) {
    return openings;
  }

  const providerWorkDays = Object.keys(providerUnavailability)
  const filterByClosedTimes: StampedOpenings = {};

  Object.entries(openings)?.forEach(([dateKey, openingsArray]) => {
    const day = dayjs(dateKey);
    const dayOfWeek = day.format('dddd').toLowerCase();
    if (providerWorkDays.includes(dayOfWeek) === false) {
      return
    } else {
      const providerUnavailabilityDay: ClosedTime[] | undefined = providerUnavailability[dayOfWeek];
      
      openingsArray?.forEach((opening) => {
        if (!canWork(provider, opening) || !providerUnavailabilityDay) {
          return;
        }
        if (!isOpeningConflicting({opening, providerUnavailabilityDay, dateKey})) {
          filterByClosedTimes[dateKey] = filterByClosedTimes?.[dateKey] || [];
          filterByClosedTimes[dateKey].push(opening);
        }
      }); 
    }
  });
  return filterByClosedTimes;
};

export const convertToMinutes = (value: number) => Math.round(value / 60);

export const correctedDurationMinutes = (durationMinutes: string | number) => Math.max(+durationMinutes, 15)

export const explodeAvailableOpenings = ({
  openings,
  durationMinutes,
  cadence,
}: ExplodeOpeningsParams): OpeningV2[] => {
  durationMinutes = correctedDurationMinutes(durationMinutes)

  const openingsMap = openings?.map((opening) => {
    let explodedOpenings: OpeningV2[] = [];
    
    const openingStart = dayjs(opening.start)
    const openingDuration = convertToMinutes(opening?.duration as number);
    const workstations: ScheduleAsset[] = opening?.assetMap?.workstations ?? [];
    const providers: ScheduleAsset[] = opening?.assetMap?.providers ?? [];

    if (cadence !== null) {
      let startMinutes = 0;
      while (startMinutes + durationMinutes <= openingDuration) {
        let startTime = openingStart.add(startMinutes, 'minute')
        const startTimeMinutes = startTime.minute();
        // If cadence is zero we want each opening to fall on the hour so we adjust to 60
        const cadenceMultiplier = !cadence ? 60 : cadence;
        // find the next interval of the specified cadence, then add the difference to startMinutes
        const minutesTilCadence = getMinutesTilCadence(cadenceMultiplier, startTimeMinutes);

        // if opening is done
        if (minutesTilCadence + startMinutes + durationMinutes <= openingDuration) {
          startMinutes += minutesTilCadence;
          startTime = openingStart.add(startMinutes, 'minute')
          explodedOpenings.push({
            start: startTime.toISOString(),
            duration: durationMinutes * 60,
            assets: [...providers, ...workstations],
            assetMap: opening?.assetMap,
          });
        }
        // if openings is still going
        startMinutes += durationMinutes;
      }
    } else {
      for (let i = 0; i < openingDuration; i += durationMinutes) {
        if (i + durationMinutes <= openingDuration) {
          let start = openingStart.add(i, 'minute').toISOString()
          explodedOpenings.push({
            start,
            duration: durationMinutes * 60,
            assets: [...providers, ...workstations],
            assetMap: opening?.assetMap,
          });
        }
      }
    }
    return explodedOpenings;
  }) || [];
  // transform the array of arrays into an array of objects
  return openingsMap.reduce((acc, val) => [...acc, ...val], []);
};

export const roundToFifteen = (date: Dayjs): Dayjs => {
  const quarterHour = Math.round(dayjs(date).minute() / 15) % 60
  return date.minute(quarterHour * 15).second(0)
}

export const calcStartDate = (buffer: number) =>  roundToFifteen(dayjs().add(buffer, 'second'));

export const sortTimes = (openings: OpeningV2[]): ExplodeOpeningsReturn => {
  if (!openings) {
    return { AM: [], PM: [] };
  }
  const distinctTimes = distinctStartTimes(openings);
  const sortedTimes = distinctTimes.sort((openA: OpeningV2, openB: OpeningV2) =>
    openA?.start.localeCompare(openB?.start)
  );
  const AM = sortedTimes.filter((opening) => {
    return dayjs(opening?.start).hour() < 12;
  });
  const PM = sortedTimes.filter((opening) => dayjs(opening?.start).hour() >= 12);
  return { AM, PM };
};
