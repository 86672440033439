import { Text, Heading, XIcon, IconButton } from '@weave/design-system';
import { theme } from '@weave/theme-original';
import { Steps } from '../wizard.types';
import { WizardObject } from '../../hooks/use-wizard';
import { useStore } from '../../context/use-store.hook';
import { Store } from '../../context/store';

type ProgressBarProps<T extends WizardObject> = {
    title: string;
    wizardPaths: (keyof T)[];
    step?: Steps;
};

export const ProgressBar = <T extends WizardObject>({ step, wizardPaths, title }: ProgressBarProps<T>) => {
    const {
        store: { isFullScreen, config },
    } = useStore(Store);
    const { locationName = '' } = config ?? {};
    return (
        <div css={{ marginLeft: theme.spacing(2), position: 'relative', width: '100%' }}>
            {step === 'Location:' && (
                <Text color="light" css={{ marginBottom: 0 }} size="small">
                    {step}
                </Text>
            )}
            {step && step !== 'Location:' && (
                <Text color="light" css={{ marginBottom: 0 }} size="small">
                    Step {wizardPaths.indexOf(step) + 1} of {wizardPaths.length}
                </Text>
            )}
            <Heading css={{ margin: 0 }} level={2}>
                {locationName || title}
            </Heading>
            {isFullScreen && (
                <IconButton
                    label="Close widget"
                    css={{ position: 'absolute', right: 12, top: 10 }}
                    onClick={() => window?.parent?.postMessage('closeWidget', '*')}
                >
                    <XIcon />
                </IconButton>
            )}
        </div>
    );
};
