import { useContext } from 'react';
import { ContextInterface, StoreState } from './store.model';

export const useStore = (store) => {
    const { storeState, storeDispatch } = useContext<ContextInterface>(store);

    const storeSelector = <T extends any>(fn: (s: StoreState) => T) => fn(storeState);

    return {
        store: storeState,
        dispatch: storeDispatch,
        selector: storeSelector,
    };
};
