import { useEffect } from 'react';
import { CaretLeftIcon, IconButton } from '@weave/design-system';
import { Store } from '../../context/store';
import { useStore } from '../../context/use-store.hook';
import { theme } from '@weave/theme-original';
import { css } from '@emotion/core';
import { WizardPage } from '../../generic/wizard-page';
import { FlowSelect } from './flow-select';
import { LocationSelect } from './location-select.component';
import { useWizard } from '../../hooks/use-wizard';
import { useHistory } from 'react-router-dom';
import { PoweredByWeave } from '../../generic/components/powered-by-weave.component';
import { ProgressBar } from '../../generic/components/progress-bar';
import { StoreState } from '../../context/store.model';
import { getApptTypes } from '../wizard-helpers';

const configurationWizard = {
    'select-location': {
        Component: LocationSelect,
        currentSelection: (store: StoreState) => store?.activeLocation?.displayName,
        title: 'Choose an office location',
        onBackAction: 'RESTART',
        validation: (store: StoreState) => !!store?.activeLocation?.displayName,
    },
    'select-app': {
        title: (store: StoreState) => store?.activeLocation?.displayName,
        onNextAction: '',
        Component: FlowSelect,
        validation: () => true,
    },
} as const;

export const ConfigurationWizard = () => {
    const { store, dispatch } = useStore(Store);
    const history = useHistory();

    const {
        activeLocation: { locationId },
        appointments,
    } = store;

    const { step, current, wizardPaths } = useWizard({
        wizard: configurationWizard,
        type: 'configuration',
        controls: { SUBMIT: () => true },
    });

    const isFirstStep = step === wizardPaths?.[0];

    const navigateBack = () => {
        if (!isFirstStep) {
            history.push(`/${store?.config?.locationId}/configuration/${wizardPaths?.[0]}`);
        }
    };

    useEffect(() => {
        if (locationId && !appointments?.length) {
            getApptTypes({ locationId, dispatch });
        }
    }, [locationId, appointments?.length]);

    return (
        <WizardPage>
            <WizardPage.Header>
                <div
                    css={css`
                        width: 100%;
                        display: flex;
                        align-items: center;
                        max-width: 600px;
                    `}
                >
                    {!isFirstStep && (
                        <IconButton label="Back" css={{ marginLeft: theme.spacing(1) }} onClick={() => navigateBack()}>
                            <CaretLeftIcon />
                        </IconButton>
                    )}
                    <ProgressBar
                        title={
                            typeof current?.title === 'function'
                                ? current?.title(store)
                                : current?.title || 'Select an option below'
                        }
                        wizardPaths={wizardPaths}
                        step={current ? 'Location:' : ''}
                    />
                </div>
            </WizardPage.Header>
            <WizardPage.Main>
                <WizardPage.Card>{typeof current?.Component === 'function' && <current.Component />}</WizardPage.Card>
            </WizardPage.Main>
            {isFirstStep && <PoweredByWeave />}
        </WizardPage>
    );
};
