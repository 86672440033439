import React, { useMemo } from 'react';
import { css } from '@emotion/core';
import { Text } from '@weave/design-system';
import { theme } from '@weave/theme-original';
import { Dayjs } from 'dayjs';
import { Openings } from './openings';
import { sortTimes } from '../date-time-helpers';
import { Store } from '../../../../context/store';
import { OpeningV2 } from '../../../../context/store.model';
import { useStore } from '../../../../context/use-store.hook';

export type ScrollRefType = React.RefObject<HTMLDivElement>;

interface Props {
    scrollRef: ScrollRefType;
    selectedDate: Dayjs;
    openings: OpeningV2[];
}

const timeSelectStyles = css`
    max-width: 400px;
    padding: ${theme.spacing(2)};
    margin: 0 auto;
`;
const apptsStyle = (colorType: 'error' | 'default') => css`
    font-size: ${theme.fontSize(20)};
    font-weight: ${theme.font.weight.bold};
    span {
        color: ${theme.colors[colorType]};
        font-size: ${theme.fontSize(20)};
        font-weight: ${theme.font.weight.bold};
    }
`;

export const AvailableTimes = ({ scrollRef, selectedDate, openings = [] }: Props) => {
    const {
        store: {
            appointmentConfig: apptConfig,
            selectedOpening,
            selectedAppointment: { durationMinutes },
        },
    } = useStore(Store);

    const durationMins = useMemo(() => {
        const duration = Number(durationMinutes);
        if (duration < 5) {
            console.warn('duration was set to less than five, that is not good');
            return 15;
        }
        return duration;
    }, [durationMinutes]);

    const slots = useMemo(() => {
        return apptConfig?.availableSlots || 3;
    }, [apptConfig?.availableSlots]);

    const { AM, PM } = sortTimes(openings);
    const formattedHrs = Math.floor(durationMins / 60);
    const formattedMins = durationMins % 60;
    const dateTitle = selectedDate ? selectedDate.format('MMMM D, YYYY') : 'Select a day';
    const apptTimesColor = selectedOpening?.length !== slots ? 'error' : 'default';

    return (
        <div css={timeSelectStyles} ref={scrollRef}>
            {slots > 1 && (
                <Text css={apptsStyle(apptTimesColor)}>
                    <Text as="span">
                        ({selectedOpening?.length}/{slots})
                    </Text>{' '}
                    appointment times selected.
                </Text>
            )}
            {!AM?.length && !PM?.length && (
                <Text textAlign="center" color="light">
                    Select a different date to see some available times.
                </Text>
            )}
            {(!!AM?.length || !!PM?.length) && (
                <>
                    <Text weight="bold" size="large">
                        {dateTitle}
                    </Text>
                    <Text color="light" size="medium">
                        Appointment will take {!!formattedHrs && `${formattedHrs} hour${formattedHrs > 1 ? 's' : ''}`}
                        {!!formattedMins && `${formattedMins} minute${formattedMins > 1 ? 's' : ''}`}
                    </Text>
                    <Openings openings={AM} category="AM" />
                    <Openings openings={PM} category="PM" />
                </>
            )}
        </div>
    );
};
