import React, { useCallback } from 'react';
import { css } from '@emotion/core';
import { Text, Heading, PrimaryButton } from '@weave/design-system';
import { theme } from '@weave/theme-original';
import { PaperAirplane } from '../../../images';
import { useStore } from '../../../context/use-store.hook';
import { Store } from '../../../context/store';
import { wizardFirstSteps, WizardFlows } from '../../../generic/wizard.types';
import { useHistory } from 'react-router-dom';

export const SuccessMessage = () => {
    const {
        dispatch,
        store: {
            config: { locationId, autoAppSelect },
        },
    } = useStore(Store);
    const history = useHistory();

    const startOver = useCallback(() => {
        dispatch({ type: 'RESET-INITIAL-STORE' });
        if (autoAppSelect === 'textConnect') {
            history.push(`/${locationId}/${WizardFlows.Configuration}/${wizardFirstSteps[WizardFlows.TextConnect]}`);
        } else if (autoAppSelect === 'onlineScheduling') {
            history.push(
                `/${locationId}/${WizardFlows.Configuration}/${wizardFirstSteps[WizardFlows.AppointmentRequests]}`
            );
        } else {
            history.push(`/${locationId}/${WizardFlows.Configuration}/${wizardFirstSteps[WizardFlows.Configuration]}`);
        }
    }, [autoAppSelect, locationId, history, dispatch]);

    return (
        <article
            css={css`
                padding: ${theme.spacing(2)};
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
            `}
        >
            <Heading textAlign="center">Thank you! Your appointment request has been submitted.</Heading>
            <PaperAirplane />
            <Text css={{ maxWidth: '90%' }} textAlign="center">
                You can now close this page or click "Start Over" to submit another request.
            </Text>
            <PrimaryButton css={{ width: 137 }} onClick={startOver}>
                Start over
            </PrimaryButton>
        </article>
    );
};
