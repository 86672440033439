import React from 'react';
import { css } from '@emotion/core';
import { NakedA, Text } from '@weave/design-system';
import { theme } from '@weave/theme-original';

const poweredByContainer = css`
    width: max-content;
    display: flex;
    max-height: 32px;
    position: absolute;
    bottom: 0;
    right: 0;
    margin: ${theme.spacing(2, 4)};
    p {
        padding: 0;
        margin: ${theme.spacing(0, 1)};
    }
    img {
        padding: 0;
        margin: auto;
    }
`;

export const PoweredByWeave = () => (
    <NakedA css={poweredByContainer} href="https://www.getweave.com/" target="blank">
        <Text color="light" size="medium">
            Powered by
        </Text>
        <img
            width="70"
            src="https://storage.googleapis.com/desktop-client-assets/images/weave-logo.png"
            alt="powered by weave"
        />
    </NakedA>
);
