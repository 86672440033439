import { useState, useEffect, useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useStore } from '../context/use-store.hook';
import { Store } from '../context/store';
import { WizardControlFn, wizardFirstSteps, WizardFlows, Wizards, WizardStep } from '../generic/wizard.types';

type WizardControls = Record<string, WizardControlFn>;
export type WizardObject = Record<string, WizardStep>;

type Props<T extends WizardObject> = {
    type: Wizards;
    wizard: T;
    controls: WizardControls;
};

type Params<T extends WizardObject, K extends keyof T = keyof T> = {
    step: K;
    locationID: string;
};

type ReturnProps<T extends WizardObject, K extends keyof T = keyof T> = {
    step: K;
    wizardPaths: K[];
    current: WizardStep;
    wizardControl: WizardControlFn;
};

export function useWizard<T extends Record<string, WizardStep>, K extends keyof T = keyof T>({
    wizard,
    controls,
}: Props<T>): ReturnProps<T> {
    const { params } = useRouteMatch();
    const { step, locationID } = params as Params<T>;
    const { store, dispatch } = useStore(Store);
    const history = useHistory();
    const [wizardPaths, setWizardPaths] = useState(Object.keys(wizard) as K[]);
    const isMultiLocation = store?.config?.assistant?.locationConfigurations?.length > 1;
    const hasDisabledFeatures = store?.activeLocation?.disabledFeatures?.length;

    useEffect(() => {
        if (hasDisabledFeatures && !isMultiLocation) {
            // console.log('should something happen here?');
        }
        // if there is only one location, remove the select-location step since it's not necessary;
        if (!isMultiLocation && wizardPaths[0] === 'select-location') {
            setWizardPaths((wizardPaths) => wizardPaths.splice(1));
            history.push(
                `/${locationID}/${WizardFlows?.Configuration}/${wizardFirstSteps[WizardFlows?.Configuration]}`
            );
        }
        // eslint-disable-next-line
    }, [isMultiLocation]);

    useEffect(() => {
        if (step === wizardFirstSteps?.[WizardFlows?.AppointmentRequests]) {
            if (store?.filteredProviders?.length < 1 && !!store?.selectedAppointment?.id) {
                //@ts-ignore
                setWizardPaths(['select-appt-type', 'select-date-time', 'contact-info', 'success']);
            }
            if (store?.filteredProviders?.length > 0 && !!store?.selectedAppointment?.id) {
                //@ts-ignore
                setWizardPaths(['select-appt-type', 'providers', 'select-date-time', 'contact-info', 'success']);
            }
        }
    }, [store?.filteredProviders, store?.selectedAppointment?.id]);

    const handleRestart: WizardControlFn = useCallback(async () => {
        dispatch({ type: 'RESET-INITIAL-STORE' });
        let restartStep: string = wizardFirstSteps?.[WizardFlows?.Configuration];
        if (store?.config?.autoAppSelect === 'onlineScheduling') {
            restartStep = wizardFirstSteps?.[WizardFlows?.AppointmentRequests];
        } else if (store?.config?.autoAppSelect === 'textConnect') {
            restartStep = wizardFirstSteps?.[WizardFlows?.TextConnect];
        }
        history.push(`/${locationID}/${WizardFlows?.Configuration}/${restartStep}`);
        return false;
    }, [dispatch, history, locationID, store?.config?.autoAppSelect]);

    const handleClose: WizardControlFn = async () => {
        dispatch({ type: 'RESET-INITIAL-STORE' });
        history.push(`/${locationID}/schedule`);
        window?.parent?.postMessage('closeWidget', '*');
        return false;
    };

    const wizardControl: WizardControlFn = async (controlType) => {
        if (controlType in controls) {
            return await controls[controlType]();
        } else {
            switch (controlType) {
                case 'RESTART':
                    return handleRestart();
                case 'CLOSE':
                    return handleClose();
            }
        }
        return false;
    };

    useEffect(() => {
        // Because each wizard step is a reflection of the pathname,
        // we need to check whether past steps were done or not.
        // If not we redirect to the first step.
        for (const path of wizardPaths) {
            if (path === step) {
                break;
            }
            const isWizardStepComplete = wizard[path]?.validation(store);
            if (!isWizardStepComplete) {
                if (isMultiLocation) {
                    // if the office has multiple locations, prompt the user to select a location
                    history.push(`/${locationID}/${WizardFlows?.Configuration}/select-location`);
                } else {
                    // if the office is single location, advance the user to select the app
                    history.push(`/${locationID}/${WizardFlows?.Configuration}/select-app`);
                }
            }
        }
        // eslint-disable-next-line
    }, []);

    return { step, current: wizard[step], wizardPaths, wizardControl };
}
