import { useStore } from '../../context/use-store.hook';
import { CustomAxios } from '../../custom-axios';
import { Store } from '../../context/store';
import dayjs from 'dayjs';

type OpeningRequest = 'appointment' | 'link';

export const useRequestOpening = () => {
    const {
        store: {
            personInfo,
            selectedAppointment,
            selectedOpening,
            activeLocation: { locationId },
            config: { linkHash },
        },
    } = useStore(Store);

    const requestOpening = async (type: OpeningRequest) => {
        const openings = selectedOpening.map((item) => ({
            dateTime: item.start,
            assets: {
                operatories: item?.assetMap?.workstations?.map((workstation) => workstation.name) ?? [],
                providers: item?.assetMap?.providers?.map((provider) => provider.name) ?? [],
            },
        }));
        if (type === 'appointment') {
            const { durationMinutes, name } = selectedAppointment;
            const duration = `${parseInt(durationMinutes) * 60}`;
            const requestBody = {
                locationId,
                dateTime: openings?.[0]?.dateTime,
                duration,
                scheduler: {
                    firstName: personInfo.firstName,
                    lastName: personInfo.lastName,
                    phoneNumber: personInfo?.phone?.replace(/\D/g, ''),
                    email: personInfo.email,
                    gender: personInfo.gender,
                    birthDate: dayjs(personInfo.birthdate).format('YYYY-MM-DD'),
                    existingUser: false,
                    insuranceInfo: JSON.stringify({ name: 'Select Health' }),
                },
                schedulee: null,
                appointmentType: name,
                note: personInfo.note,
                requestedOpenings: openings,
            };
            return CustomAxios.post<OpeningRequest>(`schedule/api/v2/requests`, requestBody);
        }
    };

    return requestOpening;
};
