import { useCallback, useContext, useEffect } from 'react';
import { Store } from '../context/store';

enum ControllerMessages {
    fullScreen = 'widgetIsFullScreen',
    notFullScreen = 'widgetIsNotFullScreen',
}

export const useWidgetController = () => {
    const { storeDispatch } = useContext(Store);

    const handleControllerMessages = useCallback(
        (event) => {
            if (event?.data === ControllerMessages?.fullScreen) {
                storeDispatch({ type: 'SET-FULL-SCREEN', payload: true });
            }
            if (event?.data === ControllerMessages?.notFullScreen) {
                storeDispatch({ type: 'SET-FULL-SCREEN', payload: false });
            }
        },
        [storeDispatch]
    );

    useEffect(() => {
        window.addEventListener('message', handleControllerMessages);
        return () => {
            window.removeEventListener('message', handleControllerMessages);
        };
    }, [storeDispatch, handleControllerMessages]);
};
