import React, { useContext, useEffect, useState } from 'react';
import {
    Text,
    PhoneField,
    TextareaField,
    TextField,
    AlertIcon,
    FormRow,
    useForm,
    CheckboxField,
} from '@weave/design-system';
import { theme } from '@weave/theme-original';
import { css } from '@emotion/core';
import { TextConnectIllustration } from '../../images';
import { Store } from '../../context/store';
import { useCaptcha } from '../../hooks/resources/use-captcha.hook';
import { CaptchaActions } from '../../recapcha.provider';

const figureStyle = css`
    margin: auto;
    margin-bottom: ${theme.spacing(2)};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    figcaption {
        display: flex;
        flex-direction: row;
        align-items: center;
        svg {
            margin-right: ${theme.spacing(1)};
            width: 124px;
        }
    }
`;

export const TextConnectForm = () => {
    const {
        storeState: {
            personInfo: { firstName, lastName, phone, note },
            config,
        },
        storeDispatch,
    } = useContext(Store);

    const [messageError, setMessageError] = useState('');
    const { captcha, clearError, loadToken } = useCaptcha(CaptchaActions?.webContact);

    const { getFieldProps, values, isComplete } = useForm({
        fields: {
            firstName: { type: 'text', value: firstName, required: true },
            lastName: { type: 'text', value: lastName, required: true },
            phone: { type: 'phone', value: phone, required: true },
            note: { type: 'text', value: note, required: true },
            checkbox: { type: 'checkbox', required: true },
        },
    });

    const validateMessageLength = () => {
        if ({ ...getFieldProps('note') }?.touched) {
            const messageLength = values?.note?.length ?? 0;
            const withinLimits = messageLength >= 5 && messageLength <= 500;
            setMessageError(withinLimits ? '' : 'The message character count must be between 5 and 500.');
        }
    };

    useEffect(() => {
        loadToken();
    }, [loadToken]);

    useEffect(() => {
        validateMessageLength();
        storeDispatch({
            type: 'PERSON-INFO',
            payload: { ...values, isComplete },
        });
        // eslint-disable-next-line
    }, [Object.values(values ?? {}).toString()]);

    // why does the error get cleared after 2 seconds?
    useEffect(() => {
        if (captcha?.error) {
            setTimeout(() => {
                clearError();
            }, 2000);
        }
    }, [captcha, clearError]);

    return (
        <section css={{ padding: theme.spacing(2) }}>
            <figure css={figureStyle}>
                <figcaption>
                    <TextConnectIllustration />
                    <span>Please enter your name, number, and message for our team!</span>
                </figcaption>
            </figure>
            <form>
                <FormRow>
                    <TextField {...getFieldProps('firstName')} name="firstName" label="First Name" />
                    <TextField {...getFieldProps('lastName')} name="lastName" label="Last Name" />
                </FormRow>
                <FormRow>
                    <PhoneField {...getFieldProps('phone')} name="phone" label="Phone Number" />
                </FormRow>
                <FormRow>
                    <TextareaField {...getFieldProps('note')} name="note" label="Note" />
                </FormRow>
            </form>
            {!!messageError && (
                <Text color="error" size="small" css={{ margin: 0 }}>
                    {messageError}
                </Text>
            )}
            {captcha?.error && (
                <Text as="span" css={{ display: 'flex', marginBottom: theme.spacing(2), alignItems: 'center' }}>
                    <AlertIcon color="error" css={{ marginRight: theme.spacing(1) }} />
                    <Text as="span" color="error">
                        {captcha?.error?.message} Error message
                    </Text>
                </Text>
            )}
            <FormRow>
                <CheckboxField
                    {...getFieldProps('checkbox')}
                    name="checkbox"
                    label={
                        <Text as="label" size="small" color="light">
                            Communication with our office is fully encrypted and HIPAA compliant. By checking the box,
                            you consent to receive electronic communication from {config?.locationName || 'us'}.
                        </Text>
                    }
                />
            </FormRow>
        </section>
    );
};
