import { useState, useEffect } from 'react';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import { WizardPage } from '../../generic/wizard-page';
import { ProgressBar } from '../../generic/components/progress-bar';
import { useWizardNavigation } from '../../hooks/use-wizard-navigation';
import { WizardControlFn, WizardFlows } from '../../generic/wizard.types';
import { TextConnectForm } from './text-connect-form.component';
import { TextConnectSuccess } from './text-connect-success.component';
import { useWizard } from '../../hooks/use-wizard';
import { useCaptcha } from '../../hooks/resources/use-captcha.hook';
import { CaptchaActions } from '../../recapcha.provider';
import { Store } from '../../context/store';
import { useStore } from '../../context/use-store.hook';
import { CustomAxios } from '../../custom-axios';
import { Text, CaretLeftIcon, IconButton } from '@weave/design-system';
import { DisplaySwitch } from '../../generic/components/display-switch';

const textConnectWizard = {
    'contact-info': {
        title: 'Text Us',
        nextLabel: 'Send Text',
        onNextAction: 'SUBMIT',
        Component: TextConnectForm,
        validation: (store) => store?.personInfo?.isComplete && store?.personInfo?.note?.length >= 5,
    },
    success: {
        Component: TextConnectSuccess,
        backLabel: 'Close',
        nextLabel: 'Restart',
        onBackAction: 'CLOSE',
        onNextAction: 'RESTART',
        title: 'Message Sent!',
        validation: () => true,
    },
} as const;

export const TextConnect = () => {
    const { store } = useStore(Store);
    const { captcha, loadToken } = useCaptcha(CaptchaActions?.webContact);

    const { firstName, lastName, phone: phoneNumber, note: messageBody } = store?.personInfo ?? {};
    const token = captcha?.token;
    const [error, setError] = useState('');
    const submitForm: WizardControlFn = async () => {
        try {
            const res = await CustomAxios.post(`schedule/v1/${store?.activeLocation?.locationId}/web-contact`, {
                firstName,
                lastName,
                phoneNumber,
                messageBody,
                token,
            });
            return !!res;
        } catch (err) {
            loadToken();
            if (err instanceof Error) {
                setError(err.message);
                console.warn('Error submitting form', err);
            }
            return false;
        }
    };

    const { step, current, wizardPaths, wizardControl } = useWizard({
        wizard: textConnectWizard,
        type: WizardFlows?.TextConnect,
        controls: { SUBMIT: submitForm },
    });
    const { onBackAction, title, Component } = current;
    const { handleBack } = useWizardNavigation({ onBackAction, wizardControl, wizardPaths, step });

    useEffect(() => {
        loadToken();
        // eslint-disable-next-line
    }, []);

    return (
        <WizardPage>
            <WizardPage.Header>
                <div
                    css={css`
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        max-width: 650px;
                        padding: ${theme.spacing(2)};
                    `}
                >
                    <div css={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        {store?.config.autoAppSelect === 'both' && (
                            <DisplaySwitch sizes={['small']}>
                                <IconButton label="Back" css={{ marginRight: theme.spacing(1) }} onClick={handleBack}>
                                    <CaretLeftIcon />
                                </IconButton>
                            </DisplaySwitch>
                        )}
                        {/* @ts-ignore */}
                        <ProgressBar step={step} wizardPaths={wizardPaths} title={title} />
                    </div>
                </div>
            </WizardPage.Header>

            <WizardPage.Main>
                <WizardPage.Card>
                    {typeof Component === 'function' && <Component />}
                    {error && (
                        <Text as="span">
                            Sending text failed:
                            <Text color="error">{error}</Text>
                        </Text>
                    )}
                </WizardPage.Card>
            </WizardPage.Main>

            <WizardPage.Footer>
                <WizardPage.Buttons
                    step={step}
                    wizardPaths={wizardPaths}
                    current={current}
                    wizardControl={wizardControl}
                />
            </WizardPage.Footer>
        </WizardPage>
    );
};
