import React from 'react';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import { widthMaxQuery } from './display-switch';

const container = css`
    position: sticky;
    top: 0;
    width: 100vw;
    height: 90px;
    display: flex;
    justify-content: center;
    background-color: ${theme.colors.white};
    border-bottom: 1px solid ${theme.colors.gray300};
    z-index: 100;
    ${widthMaxQuery(`
        border-bottom: 1px solid ${theme.colors.gray300};
    `)}
`;

export const WizardHeader = ({ children }: { children: React.ReactNode }) => (
    <header css={container}>{children}</header>
);
