import { css } from '@emotion/core';
import { PrimaryButton, SecondaryButton } from '@weave/design-system';
import { theme } from '@weave/theme-original';
import { useStore } from '../../context/use-store.hook';
import { Store } from '../../context/store';
import { DisplaySwitch } from './display-switch';
import { useWizardNavigation } from '../../hooks/use-wizard-navigation';

const btnContainer = (step: string) => [
    css`
        display: flex;

        button {
            width: 120px;
            margin: ${theme.spacing(1)};
        }
    `,
    (step === 'contact-info' || step === 'success') &&
        css`
            width: 100%;
            justify-content: flex-end;
        `,
];

type WizardButtonsProps = {
    step: string;
    wizardPaths: string[];
    current: any;
    wizardControl: any;
};

export const WizardButtons = ({ step, wizardPaths, current, wizardControl }: WizardButtonsProps) => {
    const { store } = useStore(Store);
    const { backLabel, nextLabel, validation, onBackAction, onNextAction } = current ?? {};
    const isValid = validation?.(store);
    const { handleBack, handleNext } = useWizardNavigation({
        onBackAction,
        onNextAction,
        wizardControl,
        wizardPaths,
        step,
    });

    return (
        <div css={btnContainer(step)}>
            <DisplaySwitch sizes={['large']}>
                <SecondaryButton onClick={handleBack}>{backLabel ?? 'Back'}</SecondaryButton>
            </DisplaySwitch>
            <PrimaryButton disabled={!isValid} onClick={handleNext}>
                {nextLabel ?? 'Next'}
            </PrimaryButton>
        </div>
    );
};
