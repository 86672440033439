import React from 'react';
import { Text } from '@weave/design-system';
import { theme } from '@weave/theme-original';
import { PaperAirplane } from '../../images';
import { css } from '@emotion/core';

export const TextConnectSuccess = () => (
    <figure
        css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: auto;
            padding: ${theme.spacing(4)};
        `}
    >
        <PaperAirplane />
        <figcaption
            css={css`
                font-weight: bold;
                font-size: ${theme.fontSize(20)};
                margin-bottom: ${theme.spacing(1)};
            `}
        >
            Thanks for contacting us!
        </figcaption>
        <Text>We'll text you back shortly!</Text>
    </figure>
);
