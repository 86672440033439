import React, { createContext, useReducer, useEffect, Dispatch, Reducer } from 'react';
import { load, ReCaptchaInstance } from 'recaptcha-v3';
import { Config } from './context/store.model';

export enum CaptchaActions {
    webContact = 'web_contact',
    bookAppointment = 'book_appointment',
    reschedule = 'reschedule',
}

export interface Captcha<T = CaptchaActions> {
    action: T;
    token: string;
    loading: boolean;
    error?: Error;
}

export interface CaptchaStoreState {
    instance?: ReCaptchaInstance;
    captcha: { [action in CaptchaActions]?: Captcha<action> };
}

export const initialState: CaptchaStoreState = {
    captcha: {},
};

interface SetCaptcha {
    type: 'SET-ACTION';
    payload: Captcha;
}

interface SetInstance {
    type: 'SET-INSTANCE';
    payload: ReCaptchaInstance;
}

type Actions = SetCaptcha | SetInstance;

interface ContextInterface {
    storeState: CaptchaStoreState;
    storeDispatch: Dispatch<Actions>;
}

const CaptchaStore = createContext<ContextInterface>({
    storeState: { ...initialState },
    storeDispatch: () => {
        return;
    }, // This will be updated by the reducer
});

function reducer(state: CaptchaStoreState, action: Actions): CaptchaStoreState {
    switch (action.type) {
        case 'SET-INSTANCE':
            return {
                ...state,
                instance: action.payload,
            };
        case 'SET-ACTION':
            return {
                ...state,
                captcha: { ...state.captcha, [action.payload.action]: action.payload },
            };
        default:
            return { ...state };
    }
}

interface StoreInitialProps {
    initState?: CaptchaStoreState;
    config: Config;
    children: JSX.Element;
}

function CaptchaProvider(props: StoreInitialProps) {
    const [storeState, storeDispatch] = useReducer<Reducer<CaptchaStoreState, Actions>>(reducer, {
        ...initialState,
        ...props.initState,
    });
    const { captchaSiteKey = '' } = props?.config ?? {};
    const value = { storeState, storeDispatch };

    const updateCaptchaInstance = async () => {
        try {
            const captchaInstance = await load(captchaSiteKey, {
                autoHideBadge: true,
                explicitRenderParameters: { size: 'invisible' },
            });
            storeDispatch({ type: 'SET-INSTANCE', payload: captchaInstance });
        } catch (err) {
            console.warn(err);
        }
    };

    useEffect(() => {
        if (captchaSiteKey) {
            updateCaptchaInstance();
        }
        // eslint-disable-next-line
    }, [captchaSiteKey]);

    return <CaptchaStore.Provider value={value}>{props.children}</CaptchaStore.Provider>;
}

const CaptchaConsumer = CaptchaStore.Consumer;

export { CaptchaStore, CaptchaProvider, CaptchaConsumer };
