import { useHistory, useRouteMatch } from 'react-router-dom';
import { Store } from '../context/store';
import { useStore } from '../context/use-store.hook';

export const useWizardNavigation = ({ onBackAction, onNextAction = '', wizardControl, wizardPaths, step }) => {
    const {
        params: { locationID },
    } = useRouteMatch<{ locationID: string }>();
    const history = useHistory();
    const { store } = useStore(Store);
    const isMultiLocation = store?.config?.assistant?.locationConfigurations?.length > 1;
    const hasDisabledFeatures = store?.activeLocation?.disabledFeatures?.length;
    const hasAppointmentsConfigured = store?.appointments?.length > 0;

    const handleBack = async () => {
        if (step === wizardPaths[0]) {
            // if !isMultiLocation and features are disabled, there's nothing to go back to
            if (!isMultiLocation && hasDisabledFeatures) return;

            if (hasDisabledFeatures || !hasAppointmentsConfigured) {
                history.push(`/${locationID}/configuration/select-location`);
            } else {
                history.push(`/${locationID}/configuration/select-app`);
            }
            return;
        }

        if (onBackAction) {
            const shouldContinue = await wizardControl(onBackAction);
            if (!shouldContinue) {
                return;
            }
        }

        const currentIndex = wizardPaths.findIndex((value) => value === step);

        if (currentIndex > 0) {
            const nextPath = wizardPaths?.[currentIndex - 1] ?? '';

            if (nextPath) {
                history.push(`${nextPath}`);
            }
        } else {
            history.push(`/${locationID}/schedule`);
        }
    };

    const handleNext = async () => {
        if (onNextAction) {
            const shouldContinue = await wizardControl(onNextAction);
            if (!shouldContinue) {
                return;
            }
        }
        const currentIndex = wizardPaths.findIndex((value) => value === step);
        const nextPath = wizardPaths?.[currentIndex + 1] ?? '';
        if (nextPath) {
            history.push(nextPath);
        }
    };

    return { handleBack, handleNext };
};
