import axios from 'axios';

type Headers = 'Location-Id';

let axiosInstance = axios.create();

export function getParameterByName(name) {
    const url = window.location.href;
    name = name.replace(/[[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export const setHeader = (header: Headers, value: string) => {
    if (value && axiosInstance?.defaults?.headers?.common) {
        axiosInstance.defaults.headers.common[header] = value;
    } else {
        console.warn(`Invalid ${header} value ${value}`);
    }
};

export const setBaseURL = (locationId: string, resourceAPI: string) => {
    if (!locationId && !getParameterByName('wid')) {
        console.warn('Weave schedule widget cannot locationId in config or in url');
    } else {
        const activeLocationID = getParameterByName('wid') ?? locationId;
        axiosInstance.defaults.baseURL = getParameterByName('api') ?? resourceAPI;
        setHeader('Location-Id', activeLocationID);
    }
};

export const CustomAxios = axiosInstance;
