import { SingleMonthCalendarLayout, SpinningLoader, useCalendar } from '@weave/design-system';
import { theme } from '@weave/theme-original';
import { css } from '@emotion/core';
import React, { useCallback, useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { TimeSelection } from './time-selection';
import { formatSimpleDate } from '../date-time-helpers';
import { monthFormat } from '../../../../hooks/resources/use-openings.hook';
import { useStore } from '../../../../context/use-store.hook';
import { Store } from '../../../../context/store';
import { DisplaySwitch } from '../../../../generic/components/display-switch';

dayjs.extend(isSameOrAfter);

interface Props {
    monthLoader: string[];
    closedDays: string[];
    setSelectedDate: React.Dispatch<React.SetStateAction<Dayjs>>;
    viewDate: Dayjs;
    minimumDate: Dayjs;
    setViewDate: (value: Dayjs) => void;
}

export const Calendar = ({ setSelectedDate, monthLoader, closedDays, viewDate, minimumDate, setViewDate }: Props) => {
    const loading = monthLoader?.includes(dayjs(viewDate).format(monthFormat));
    const {
        dispatch,
        store: { selectedOpening: selectedOpenings },
    } = useStore(Store);
    const calendarProps = useCalendar({
        value: formatSimpleDate(viewDate),
        minDate: formatSimpleDate(minimumDate),
        blackoutDates: closedDays,
    });
    const { month, year } = calendarProps?.months[0];

    const updatedViewDate = useCallback(() => {
        const newDate = dayjs().month(month).startOf('M');
        if (!newDate.isSame(viewDate, 'day')) {
            setViewDate(newDate);
        }
    }, [setViewDate, viewDate, month]);

    const updateSelectedDate = useCallback(() => {
        const calendarDate = typeof calendarProps.value === 'string' ? calendarProps.value : calendarProps.value[0];
        setSelectedDate(dayjs(calendarDate));
    }, [calendarProps.value, setSelectedDate]);

    useEffect(() => {
        updatedViewDate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [month, year]);

    useEffect(() => {
        updateSelectedDate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calendarProps.value]);

    return (
        <div
            css={css`
                position: relative;
                margin: ${theme.spacing(3)} auto;
                width: min-content;
            `}
        >
            <DisplaySwitch sizes={['small']}>
                {selectedOpenings?.map((opening, idx) => (
                    <TimeSelection key={`time-selection-${idx}`} opening={opening} idx={idx} dispatch={dispatch} />
                ))}
            </DisplaySwitch>
            <SingleMonthCalendarLayout {...calendarProps} value={formatSimpleDate(viewDate)} />
            {loading && (
                <div
                    css={css`
                        display: flex;
                        position: absolute;
                        top: 75px;
                        bottom: -15px;
                        left: -15px;
                        right: -15px;
                        background-color: ${theme.colors.white}99;
                        border-radius: ${theme.borderRadius.small};
                        align-items: center;
                        justify-content: space-around;
                    `}
                >
                    <SpinningLoader />
                </div>
            )}
        </div>
    );
};
