import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { StoreProvider } from './context/store';
import { OriginalThemeProvider } from '@weave/design-system';
import { useWidgetController } from './hooks/use-widget-controller';
import { CaptchaProvider } from './recapcha.provider';
import { AppointmentRequestWizard } from './wizards/appointment-request/appointment-request-wizard';
import { TextConnect } from './wizards/text-connect/text-connect-wizard';
import { ConfigurationWizard } from './wizards/configuration/configuration-wizard';
import { BadRoute } from './wizards/bad-route.component';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

const App = () => {
    useWidgetController();

    return (
        <Router>
            <Switch>
                <Route exact path="/:locationID/configuration/:step" component={ConfigurationWizard} />
                <Route exact path="/:locationID/schedule" component={ConfigurationWizard} />
                <Route exact path="/:locationID/appointment-requests/:step" component={AppointmentRequestWizard} />
                <Route exact path="/:locationID/text-connect/:step" component={TextConnect} />
                {/* fallback component if above routes are not matched */}
                <Route component={BadRoute} />
            </Switch>
        </Router>
    );
};

export const AppWithProviders = ({ config }) => (
    <OriginalThemeProvider includeEmotionTheme>
        <QueryClientProvider client={queryClient}>
            <StoreProvider config={config}>
                <CaptchaProvider config={config}>
                    <App />
                </CaptchaProvider>
            </StoreProvider>
        </QueryClientProvider>
    </OriginalThemeProvider>
);

export default AppWithProviders;
