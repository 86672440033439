import { Text } from '@weave/design-system';
import { theme } from '@weave/theme-original';
import { css } from '@emotion/core';
import { useMemo } from 'react';
import dayjs from 'dayjs';
import { Detail } from './detail.component';
import { useStore } from '../../../context/use-store.hook';
import { Store } from '../../../context/store';

export const mDash = '—';

const ellipsisStyle = css`
    margin-top: 0;
    max-width: 152px;
    text-overflow: ellipsis;
    display: inline;
    white-space: nowrap;
    overflow: hidden;
`;

export const Details = () => {
    const {
        store: { selectedAppointment, selectedProvider, selectedOpening, appointmentConfig, config, activeLocation },
    } = useStore(Store);

    const isMultiLocation = config.assistant.locationConfigurations.length > 1;
    const selectedSlots =
        appointmentConfig.availableSlots > 1 && `(${selectedOpening.length}/${appointmentConfig.availableSlots})`;

    const providerName = useMemo(() => {
        return selectedProvider?.publicDisplayName || selectedProvider?.name || mDash;
    }, [selectedProvider]);
    return (
        <aside css={{ margin: theme.spacing(2) }}>
            <Text size="large" weight="semibold">
                Appointment Details
            </Text>
            {isMultiLocation && (
                <Detail category="Location">
                    <Text css={ellipsisStyle}>{activeLocation.displayName || mDash}</Text>
                </Detail>
            )}
            <Detail category="Appointment Type">
                <Text css={ellipsisStyle}>{selectedAppointment.name || mDash}</Text>
            </Detail>
            <Detail category="Provider">
                <Text css={ellipsisStyle}>{providerName}</Text>
            </Detail>
            <Detail category={`Time Preference ${selectedSlots || ''}`}>
                {selectedOpening.length ? (
                    selectedOpening.map(({ start }, idx) => (
                        <Text css={{ margin: 0 }} key={start + idx}>
                            {dayjs(start).format('MMM D, YYYY - h:mm a')}
                        </Text>
                    ))
                ) : (
                    <Text css={{ marginTop: 0 }}>&mdash;</Text>
                )}
            </Detail>
        </aside>
    );
};
