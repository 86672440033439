import React from 'react';
import { css } from '@emotion/core';
import { theme } from '@weave/theme-original';
import { widthMaxQuery } from './display-switch';

const container = css`
    display: flex;
    justify-content: center;
    width: 100%;
    max-height: 1000px;
    margin: ${theme.spacing(3)} auto;
    padding-bottom: 100px;
    ${widthMaxQuery(`
        background-color: ${theme.colors.white};
        margin-top: 0;
    `)}
`;

export const WizardMain = ({ children }) => <main css={container}>{children}</main>;
