import dayjs, { Dayjs } from 'dayjs';
import { OpeningV2, DaysStatusList, StampedOpenings } from '../../context/store.model';

export const formatOpenings = (openings: OpeningV2[]): StampedOpenings => {
    const results = openings?.reduce((prev, value) => {
        const formattedDate = dayjs(value.start).format('MM/DD/YYYY');
        return {
            ...prev,
            [formattedDate]: [...(prev[formattedDate] || []), value],
        };
    }, {}) || {}
    return results
};

type StatusListProps = {
    openings: StampedOpenings;
    start: Dayjs;
    end: Dayjs;
    provider?: string;
    assetFilter?: {
        [asset: string]: string[];
    };
};

export const getStatusList = ({ openings, start, end }: StatusListProps): DaysStatusList => {
    const current = start.date();
    const month = start.month();
    const year = start.year();
    const daysDifference = end.diff(start, 'd');
    let daysList: DaysStatusList = { open: [], closed: [] };
    for (let idx = 0; idx <= daysDifference; idx++) {
        const day = idx + current;
        const checkDate = dayjs().year(year).month(month).date(day).format('MM/DD/YYYY');
        const key = checkDate in openings ? 'open' : 'closed';
        daysList = {
            ...daysList,
            [key]: [...daysList[key], checkDate],
        };
    }
    return daysList;
};
