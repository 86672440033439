import React from 'react';
import { css } from '@emotion/core';
import { CaretLeftIcon, Heading, IconButton } from '@weave/design-system';
import { theme } from '@weave/theme-original';
import { ProgressBar } from '../../../generic/components/progress-bar';
import { useStore } from '../../../context/use-store.hook';
import { Store } from '../../../context/store';
import { DisplaySwitch } from '../../../generic/components/display-switch';
import { useWizardNavigation } from '../../../hooks/use-wizard-navigation';

export const AppointmentRequestHeader = ({ step, current, wizardPaths, wizardControl }) => {
    const {
        store: { selectedOpening, appointmentConfig: apptConfig },
    } = useStore(Store);
    const { onBackAction } = current;
    const { handleBack } = useWizardNavigation({ onBackAction, wizardControl, wizardPaths, step });

    return (
        <div
            css={css`
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                max-width: 965px;
                padding: ${theme.spacing(2)};
            `}
        >
            <div css={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <DisplaySwitch sizes={['small']}>
                    <IconButton label="Back" css={{ marginRight: theme.spacing(1) }} onClick={handleBack}>
                        <CaretLeftIcon />
                    </IconButton>
                </DisplaySwitch>
                <ProgressBar step={step} wizardPaths={wizardPaths} title={current.title ?? ''} />
            </div>
            <DisplaySwitch sizes={['large']}>
                {step === 'select-date-time' && (
                    <Heading level={2}>
                        {selectedOpening.length}/{apptConfig.availableSlots} Selected
                    </Heading>
                )}
            </DisplaySwitch>
        </div>
    );
};
